import axios from 'axios';
import * as types from './types';
// import * as FileSaver from "file-saver";
// import moment from 'moment';

export const loginAction = (dispatch, username, password, platform) => {
    axios.post(types.API_URL + 'vam/v1/login', {
        email: username,
        password: password,
        // platform: platform
    })
        .then(function (response) {
            // console.log("Login Response",response)
            if (response.data.message) {
                alert(response.data.message)
            }
            dispatch({
                type: types.LOGIN_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function (error) {
            // console.log("Login Error",error)
            dispatch({
                type: types.LOGIN_FAILURE_ACTION,
                payload: error
            });
        });
}

export const logoutAction = (dispatch,email="") => {
    try {
        dispatch({
            type: types.LOGOUT_SUCCESS_ACTION
        });
    }
    catch (error) {
        dispatch({
            type: types.LOGIN_FAILURE_ACTION
        });
    }

}


export const forgotPassword = (dispatch, data) => {
    axios.post(types.API_URL + 'vam/v1/forgotPassword', data)
        .then(function (response) {
            alert(response.data.message)
            dispatch({
                type: types.FORGOT_PASSWORD_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function (error) {
            dispatch({
                type: types.FORGOT_PASSWORD_FAILURE_ACTION,
                payload: error
            });
        });
}

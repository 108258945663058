import axios from "axios";
import * as types from "./types";

const isUnAuthorized = function (res = {}, dispatch = () => { }) {
  if (res?.['response']?.['status'] == 401) {
      dispatch({
          type: types.LOGOUT_SUCCESS_ACTION
      });

  }
}

export const fetchVendorsQuestionaction = (dispatch, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/getVendorQues`)
    .then(function (response) {
      dispatch({
        type: types.VENDOR_QUESTIONS_SUCCESS_ACTION,
        payload: response.data.vendor_data.sections,
      });
      onSuccess(response.data.vendor_data.sections);
    })
    .catch(function (error) {
      dispatch({
        type: types.VENDOR_QUESTIONS_FAILURE_ACTION,
        payload: error,
      });
      isUnAuthorized(error,dispatch)
      onFailure();
    });
};

export const fetchVendorsQuestionSectionaction = (dispatch, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/vendor/getRegistrationQuestionSections`)
    .then(function (response) {
      console.log(response.data);
      if (response.data.success == true) {
        dispatch({
          type: types.VENDOR_QUESTIONS_SECTIONS_SUCCESS_ACTION,
          payload: response.data.data,
        });
        onSuccess(response.data.data);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};

export const fetchVendorsaction = (dispatch, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/vendor/get`)
    .then(function (response) {
      dispatch({
        type: types.VENDORLIST_SUCCESS_ACTION,
        payload: response.data.data,
      });
      onSuccess(response.data.data);
    })
    .catch(function (error) {
      console.log("Errorn on get Vendors", JSON.stringify(error.message))
      dispatch({
        type: types.VENDORLIST_FAILURE_ACTION,
        payload: error,
      });
      isUnAuthorized(error,dispatch)
      onFailure();
    });
};

export const addVendorAction = (dispatch, addVendorData, { onSuccess, onFailure }) => {
  axios
    .post(types.API_URL + `vam/v1/vendor/add`, addVendorData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.ADD_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure(error);
    });
};
export const editVendorAction = (dispatch, editVendorData, { onSuccess, onFailure }) => {
  console.log("edit Vendor Data", editVendorData);
  axios
    .put(types.API_URL + `vam/v1/vendor/edit`, editVendorData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.ADD_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure(error);
    });
};
export const deleteVendorAction = (dispatch, deleteVendorData, { onSuccess, onFailure }) => {
  console.log("delete Vendor Data", deleteVendorData);
  var id = deleteVendorData._id;
  axios
    .delete(types.API_URL + `vam/v1/vendor/delete?id=${id}`, deleteVendorData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.ADD_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure(error);
    });
};
//editVendorAction
export const startVendorAction = (dispatch, startData, { onSuccess, onFailure }) => {
  axios
    .post(types.API_URL + `vam/v1/vendor/initiateRegistration`, startData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.START_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};
export const renewalVendorAction = (dispatch, startData, { onSuccess, onFailure }) => {
  axios
    .put(types.API_URL + `vam/v1/vendor/renewalLink`, startData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.START_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};
export const editVendorFormAction = (dispatch, editFormData, { onSuccess, onFailure }) => {
  try {
    dispatch({
      type: types.EDIT_VENDOR_FORM_SUCCESS_ACTION,
      payload: editFormData,
    });
    onSuccess(editFormData);
  } catch (error) {
    onFailure(error);
  }
};
export const getVendorCategoryAction = (dispatch, vendorID, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/vendor/getRegistrationFormQuestions?id=${vendorID}`, { id: vendorID })
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.START_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      console.log(error);
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};
export const submitVendorAction = (dispatch, vendorID, submitVendorData, { onSuccess, onFailure }) => {
  console.log("submit Vendor Data", submitVendorData, vendorID);
  axios
    .post(types.API_URL + `vam/v1/vendor/submitRegistrationForm`, { vendorId: vendorID, status: 'Submit', submitedRegistartionForm: submitVendorData })
    .then(function (response) {
      // alert(response.data.message)
      if (response.data.success == true) {
        dispatch({
          type: types.SUBMIT_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure(error);
    });
};

export const saveVendorAction = (dispatch, vendorID, submitVendorData, { onSuccess, onFailure }) => {
  console.log("Save Vendor Data", submitVendorData, vendorID);
  axios
    .post(types.API_URL + `vam/v1/vendor/submitRegistrationForm`, { vendorId: vendorID, status: 'Save', submitedRegistartionForm: submitVendorData })
    .then(function (response) {
      // alert(response.data.message)
      if (response.data.success == true) {
        console.log(response.data)
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure(error);
    });
};

export const approveVendorAction = (dispatch, data, { onSuccess, onFailure }) => {
  console.log("approve Vendor id isApproved", data);
  axios
    .put(types.API_URL + `vam/v1/vendor/approveRegistration?id=${data.id}`, data)
    .then(function (response) {
      // alert(response.data.message)
      if (response.data.success == true) {
        dispatch({
          type: types.APPROVE_VENDOR_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("something went wrong");
    });
};


export const downloadConsolidatedVendorReport = async (data = {}) => {
  let downloadData = {}
  if (data && typeof data == 'object') downloadData = { ...data }
  try {
    const apiResponse = await axios.post(
      `${types.API_URL}vam/v1/report/vendor/consolidatedReport`,
      downloadData,
      { responseType: "blob" }
    )
    return apiResponse;
  } catch (err) {
    return err.response;
  }
};

export const downloadVendorsList = async (data = {}) => {
  let downloadData = {}
  if (data && typeof data == 'object') downloadData = { ...data }
  try {
    const apiResponse = await axios.post(
      `${types.API_URL}vam/v1/report/vendor/all`,
      downloadData,
      { responseType: "blob" }
    )
    return apiResponse;
  } catch (err) {
    return err.response;
  }
};

export const downloadVendorFormPDF = async ({ vendorId = "" }) => {
  try {
    const apiResponse = await axios.post(
      `${types.API_URL}vam/v1/report/vendor/pdf`,
      { vendorId: vendorId },
      { responseType: "blob" }
    )
    return apiResponse;
  } catch (err) {
    return err.response;
  }
};

// //---------------------------------------------------------------------------------------------------------------------//
// //============================================     AUDIT DETAILS     =================================================//
// //--------------------------------------------------------------------------------------------------------------------//
export const fetchAuditsaction = (dispatch, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/audit/get`)
    .then(function (response) {
      console.log(response.data);
      dispatch({
        type: types.AUDIT_LIST_SUCCESS_ACTION,
        payload: response.data.data,
      });
      onSuccess(response.data.data);
    })
    .catch(function (error) {
      dispatch({
        type: types.AUDIT_LIST_FAILURE_ACTION,
        payload: error,
      });
      isUnAuthorized(error,dispatch)
      onFailure();
    });
};
export const fetchAuditQuestionaction = (dispatch, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/getAuditQues`)
    .then(function (response) {
      console.log(response.data);
      dispatch({
        type: types.AUDIT_QUESTIONS_SUCCESS_ACTION,
        payload: response.data.auditQues.sections,
      });
      onSuccess(response.data.auditQues.sections);
    })
    .catch(function (error) {
      dispatch({
        type: types.AUDIT_QUESTIONS_FAILURE_ACTION,
        payload: error,
      });
      isUnAuthorized(error,dispatch)
      onFailure();
    });
};
export const fetchAuditQuestionSectionaction = (dispatch, { onSuccess, onFailure }) => {
  axios
    .get(types.API_URL + `vam/v1/audit/getAuditQuestionSections`)
    .then(function (response) {
      console.log(response.data);
      if (response.data.success == true) {
        dispatch({
          type: types.AUDIT_QUESTIONS_SECTIONS_SUCCESS_ACTION,
          payload: response.data.data,
        });
        onSuccess(response.data.data);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};
export const startAuditAction = (dispatch, startData, { onSuccess, onFailure }) => {
  axios
    .post(types.API_URL + `vam/v1/audit/initiateAudit`, startData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.START_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};
export const renewalAuditAction = (dispatch, startData, { onSuccess, onFailure }) => {
  axios
    .put(types.API_URL + `vam/v1/audit/renewalLink`, startData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.START_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};

export const editAuditAction = (dispatch, editAuditData, { onSuccess, onFailure }) => {
  try {
    dispatch({
      type: types.EDIT_AUDIT_FORM_SUCCESS_ACTION,
      payload: editAuditData,
    });
    onSuccess(editAuditData);
  } catch (error) {
    isUnAuthorized(error,dispatch)
    onFailure(error);
  }
};

export const getAuditCategoryAction = (dispatch, vendorID, { onSuccess, onFailure }) => {
  axios
    .put(types.API_URL + `vam/v1/audit/getAuditFormQuestions?id=${vendorID}`, { id: vendorID })
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.START_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("Something went wrong");
    });
};

export const submitAuditAction = (dispatch, auditId, submitAuditData, { onSuccess, onFailure }) => {
  console.log("save Audit Data", submitAuditData, auditId);
  axios
    .post(types.API_URL + `vam/v1/audit/submitAuditForm`, { id: auditId, status: 'Submit', submittedAuditForm: submitAuditData })
    .then(function (response) {
      if (response.data.success == true) {
        // dispatch({
        //   type: types.SUBMIT_AUDIT_SUCCESS_ACTION,
        //   payload: 'submitAuditData',
        // });
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure(error);
    });
};

export const saveAuditAction = (dispatch, auditId, submitAuditData, { onSuccess, onFailure }) => {
  console.log("save Audit Data", submitAuditData, auditId);
  axios
    .post(types.API_URL + `vam/v1/audit/submitAuditForm`, { id: auditId, status: 'Save', submittedAuditForm: submitAuditData })
    .then(function (response) {
      if (response.data.success == true) {
        // dispatch({
        //   type: types.SUBMIT_AUDIT_SUCCESS_ACTION,
        //   payload: 'submitAuditData',
        // });
      }
      onSuccess(response.data);
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      console.log(error, "Error saving");
      onFailure(error);
    });
};

export const approveAuditAction = (dispatch, auditID, { onSuccess, onFailure }) => {
  var data = {
    id: auditID,
  };
  console.log("approve Audit id isApproved", data);
  axios
    .put(types.API_URL + `vam/v1/audit/approveAudit?id=${auditID}`, data)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.APPROVE_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("something went wrong");
    });
};

export const rejectAuditAction = (dispatch, auditData, { onSuccess, onFailure }) => {
  console.log("Reject Audit", auditData);
  axios
    .put(types.API_URL + `vam/v1/audit/rejectAudit?id=${auditData['id']}`, auditData)
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.APPROVE_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      } else {
        onFailure(response.data.message);
      }
    })
    .catch(function (error) {
      isUnAuthorized(error,dispatch)
      onFailure("something went wrong");
    });
};

export const downloadConsolidatedAuditReport = async (data = {}) => {
  let downloadData = {}
  if (data && typeof data == 'object') downloadData = { ...data }
  try {
    const apiResponse = await axios.post(
      `${types.API_URL}vam/v1/report/audits/consolidatedReport`,
      downloadData,
      { responseType: "blob", }
    )
    return apiResponse;
  } catch (err) {
    return err.response;
  }
};

export const downloadAuditsList = async (data = {}) => {
  let downloadData = {}
  if (data && typeof data == 'object') downloadData = { ...data }
  try {
    const apiResponse = await axios.post(
      `${types.API_URL}vam/v1/report/audits/all`,
      downloadData,
      { responseType: "blob", }
    )
    return apiResponse;
  } catch (err) {
    return err.response;
  }
};

export const downloadAuditFormPDF = async ({ auditId = "" }) => {
  try {
    const apiResponse = await axios.post(
      `${types.API_URL}vam/v1/report/audits/pdf`,
      { auditId: auditId },
      { responseType: "blob", }
    )
    return apiResponse;
  } catch (err) {
    return err.response;
  }
};
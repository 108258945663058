import axios from 'axios';
import config from '../config.js';

export const API_URL = config['api_url'];
export const STORAGE_URL = config['Storagebase_url'];

axios.defaults.baseURL = API_URL;

//auth reducer
export const LOGIN_SUCCESS_ACTION = 'login_success';
export const LOGIN_FAILURE_ACTION = 'login_failure';
export const LOGOUT_SUCCESS_ACTION = 'logout_sucess';
export const LOGOUT_FAILURE_ACTION = 'logout_failure';
export const FORGOT_PASSWORD_SUCCESS_ACTION = "FORGOT_PASSWORD_SUCCESS_ACTION";
export const FORGOT_PASSWORD_FAILURE_ACTION = "FORGOT_PASSWORD_FAILURE_ACTION";
export const SET_EMAIL = 'set_email';

//Users reducer
export const USERLIST_SUCCESS_ACTION = 'userlist_success';
export const USERLIST_FAILURE_ACTION = 'userlist_failure';
export const ADD_USER_SUCCESS_ACTION = 'add_user_success';
export const ADD_USER_FAILURE_ACTION = 'add_user_failure';
export const EDIT_USER_SUCCESS_ACTION = 'edit_user_success';
export const EDIT_USER_FAILURE_ACTION = 'edit_user_failure';
export const DELETE_USER_SUCCESS_ACTION = 'delete_user_success';
export const DELETE_USER_FAILURE_ACTION = 'delete_user_failure';
export const CHANGE_PASSWORD_SUCCESS_ACTION = "CHANGE_PASSWORD_SUCCESS_ACTION";
export const CHANGE_PASSWORD_FAILURE_ACTION = "CHANGE_PASSWORD_FAILURE_ACTION";

//Vendors reducer and Form
export const VENDORLIST_SUCCESS_ACTION = 'vendorlist_success';
export const VENDORLIST_FAILURE_ACTION = 'vendorlist_failure';
export const ADD_VENDOR_SUCCESS_ACTION = 'add_vendor_success';
export const ADD_VENDOR_FAILURE_ACTION = 'add_vendor_failure';
export const EDIT_VENDOR_FORM_SUCCESS_ACTION = 'edit_vendor_form_success';
export const EDIT_VENDOR_FORM_FAILURE_ACTION = 'edit_vendor_form_failure';
export const VENDOR_QUESTIONS_SECTIONS_SUCCESS_ACTION = 'vendor_question_sections_success';
export const VENDOR_QUESTIONS_SUCCESS_ACTION = 'vendor_question_success';
export const VENDOR_QUESTIONS_FAILURE_ACTION = 'vendor_question_failure';
export const START_VENDOR_SUCCESS_ACTION = 'start_vendor_success';
export const START_VENDOR_FAILURE_ACTION = 'start_vendor_failure';
export const SUBMIT_VENDOR_SUCCESS_ACTION = 'submit_vendor_success';
export const SUBMIT_VENDOR_FAILURE_ACTION = 'submit_vendor_failure';
export const APPROVE_VENDOR_SUCCESS_ACTION = 'approve_vendor_success';
export const APPROVE_VENDOR_FAILURE_ACTION = 'approve_vendor_failure';
export const DOWNLOAD_CONSOLIDATED_VENDOR_SUCCESS_ACTION = 'download_condolidated_vendor_success';
export const DOWNLOAD_CONSOLIDATED_VENDOR_FAILURE_ACTION = 'download_condolidated_vendor_failure';
export const DOWNLOAD_VENDOR_LIST_SUCCESS_ACTION = 'download_vendor_list_success';
export const DOWNLOAD_VENDOR_LIST_FAILURE_ACTION = 'download_vendor_list_failure';
export const DOWNLOAD_VENDOR_FORM_SUCCESS_ACTION = 'download_vendor_form_success';
export const DOWNLOAD_VENDOR_FORM_FAILURE_ACTION = 'download_vendor_form_failure';
// Vendor Audit
export const EDIT_AUDIT_FORM_SUCCESS_ACTION = 'edit_audit_form_success';
export const EDIT_AUDIT_FORM_FAILURE_ACTION = 'edit_audit_form_failure';
export const AUDIT_QUESTIONS_SECTIONS_SUCCESS_ACTION = 'audit_question_sections_success';
export const AUDIT_LIST_SUCCESS_ACTION = 'audit_list_success';
export const AUDIT_LIST_FAILURE_ACTION = 'audit_list_failure';
export const AUDIT_QUESTIONS_SUCCESS_ACTION = 'audit_question_success';
export const AUDIT_QUESTIONS_FAILURE_ACTION = 'audit_question_failure';
export const START_AUDIT_SUCCESS_ACTION = 'start_audit_success';
export const START_AUDIT_FAILURE_ACTION = 'start_audit_failure';
export const SUBMIT_AUDIT_SUCCESS_ACTION = 'submit_audit_success';
export const SUBMIT_AUDIT_FAILURE_ACTION = 'submit_audit_failure';
export const APPROVE_AUDIT_SUCCESS_ACTION = 'approve_audit_success';
export const APPROVE_AUDIT_FAILURE_ACTION = 'approve_audit_failure';
export const DOWNLOAD_CONSOLIDATED_AUDIT_SUCCESS_ACTION = 'download_condolidated_audit_success';
export const DOWNLOAD_CONSOLIDATED_AUDIT_FAILURE_ACTION = 'download_condolidated_audit_failure';
export const DOWNLOAD_AUDIT_LIST_SUCCESS_ACTION = 'download_audit_list_success';
export const DOWNLOAD_AUDIT_LIST_FAILURE_ACTION = 'download_audit_list_failure';
export const DOWNLOAD_AUDIT_FORM_SUCCESS_ACTION = 'download_audit_form_success';
export const DOWNLOAD_AUDIT_FORM_FAILURE_ACTION = 'download_audit_form_failure';
//Calendar
export const CALENDAR_VIEW_SUCCESS_ACTION = 'calendar_view_success';
export const CALENDAR_VIEW_FAILURE_ACTION = 'calendar_view_failure';
export const CALENDAR_LIST_SUCCESS_ACTION = 'calendar_list_success';
export const CALENDAR_LIST_FAILURE_ACTION = 'calendar_list_failure';
export const GET_CALENDAR_SUCCESS_ACTION = 'get_calendar_success';
export const GET_CALENDAR_FAILURE_ACTION = 'get_calendar_failure';
export const ADD_CALENDAR_SUCCESS_ACTION = 'add_calendar_success';
export const ADD_CALENDAR_FAILURE_ACTION = 'add_calendar_failure';
export const EDIT_CALENDAR_SUCCESS_ACTION = 'edit_calendar_success';
export const EDIT_CALENDAR_FAILURE_ACTION = 'edit_calendar_failure';
export const DELETE_CALENDAR_SUCCESS_ACTION = 'delete_calendar_success';
export const DELETE_CALENDAR_FAILURE_ACTION = 'delete_calendar_failure';

import {
  USERLIST_SUCCESS_ACTION,
  USERLIST_FAILURE_ACTION,
  ADD_USER_SUCCESS_ACTION,
  ADD_USER_FAILURE_ACTION,
  EDIT_USER_SUCCESS_ACTION,
  EDIT_USER_FAILURE_ACTION,
  DELETE_USER_SUCCESS_ACTION,
  DELETE_USER_FAILURE_ACTION,
} from "../actions/types";

const INIT_STATE = {};

export default function user_reducer(state = INIT_STATE, action) {
  state = Object.assign({}, state, {});
  switch (action.type) {
    case USERLIST_SUCCESS_ACTION:
      state.allusers = action.payload;
      state.usererror = false;
      return state;
    case USERLIST_FAILURE_ACTION:
      state.allusers = [];
      state.usererror = true;
      return state;
    case ADD_USER_SUCCESS_ACTION:
      state.idcard = "";
      state.adduser = action.payload;
      state.addError = false;
      return state;
    case ADD_USER_FAILURE_ACTION:
      state.addError = true;
      return state;
    case EDIT_USER_SUCCESS_ACTION:
      state.edituser = action.payload;
      state.editError = false;
      return state;
    case EDIT_USER_FAILURE_ACTION:
      state.editError = true;
      return state;
    case DELETE_USER_SUCCESS_ACTION:
      state.deleteuser = action.payload;
      state.deleteError = false;
      return state;
    case DELETE_USER_FAILURE_ACTION:
      state.deleteError = true;
      return state;
  }
  return state;
}

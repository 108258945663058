import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import { isLoggedIn } from "./../../util";
import {
  Icon,
  Label,
  Modal,
  Grid,
  Form,
  Loader,
  Button,
  Popup
} from "semantic-ui-react";
import {
  fetchUseraction,
  adduserAction,
  EditUserAction,
  DeleteUserAction,
  changePasswordAction,

} from "../../actions/users_action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../actions";

const Users = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const userState = useSelector((state) => state.user)
  const [state, setStateAction] = useState({
    Editmode: false,
    deleteMode: false,
    changePassMode: false,
    name: "",
    email: "",
    phonenumber: "",
    userId: "",
    user_id: "",
    editedData: false,
    loading: false,
    password: "",
    usersList: [
      // { _id: 0, displayName: "user", email: "user@gmail.com", mobileNo: "4546545454", password: "sdf5454", editing: "", delete: "" },
    ]
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }

  useEffect(() => {
    setState({ loading: true })
    fetchUseraction(
      dispatch,
      {
        onSuccess: (data) => { setState({ loading: false }) },
        onFailure: () => { setState({ loading: false }) }
      });
  }, [])

  useEffect(() => {
    if (userState.allusers) {
      setState({ usersList: userState.allusers })
    }
  }, [userState.allusers])

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
      navigate(`/login`, { replace: true });
    }
  }, [authState.isLoggedIn])


  const Namechange = (e) => {
    setState({
      name: e.target.value,
    });
  };
  const passwordChange = (e) => {
    setState({
      password: e.target.value,
    });
  };

  const Emailchange = (e) => {
    setState({
      email: e.target.value,
    });
  };
  const Numberchange = (e) => {
    setState({
      phonenumber: e.target.value,
    });
  };
  const addNewUser = () => {
    setState({
      Editmode: true,
      editedData: false,
      name: "",
      email: "",
      phonenumber: "",
      password: "",
    });
  };

  const closeModal = () => {
    setState({
      Editmode: false,
    });
  };
  const closeDeleteModal = () => {
    setState({
      deleteMode: false,
    });
  }
  const closeChangeModal = () => {
    setState({
      changePassMode: false,
    });
  }
  const editUserDetail = (data) => {
    // console.log("Edit Data", data)
    setState({
      user_id: data._id,
      userId: data.id,
      Editmode: true,
      editedData: true,
      name: data.displayName,
      email: data.email,
      role: data.role,
      phonenumber: data.mobileNo,
      password: ""

    });
  };
  const changePasswordDetails = (data) => {
    setState({
      user_id: data._id,
      changeData: data,
      changePassMode: true,
    });
  };

  const deleteUser = (data) => {
    // console.log("DeleteData",data)
    setState({ deleteMode: true, deleteData: data })
  };
  const deleteUserNext = () => {
    var deleteId = state.deleteData._id
    DeleteUserAction(dispatch, deleteId, {
      onSuccess: () => {
        setState({ loading: true, deleteMode: false })
        fetchUseraction(dispatch, {
          onSuccess: () => { setState({ loading: false }) },
          onFailure: () => { setState({ loading: false }) }
        });
      },
      onFailure: () => { },
    });
  }



  const changePassword = () => {
    setState({ loading: true })
    let changeData = {
      id: state.changeData._id,
      password: state.password
    }
    changePasswordAction(
      dispatch,
      changeData,
      {
        onSuccess: (res) => {
          setState({ loading: false })
          // alert(res.message)
          if (res.success == true) {
            setState({ loading: true, changePassMode: false })
            fetchUseraction(dispatch, {
              onSuccess: () => { setState({ loading: false }) },
              onFailure: () => { setState({ loading: false }) }
            });
          }
        },
        onFailure: (res) => {
          alert(res.message)
          setState({ loading: false })
          alert("User Password Not Updated")
        },
      }
    );
  }

  const edituser = () => {
    setState({ loading: true })
    let displayName = state.name;
    let email = state.email;
    let mobileNo = state.phonenumber;
    let role = state.role;
    let _id = state.user_id;
    let id = state.userId
    let password = state.password
    let editData = { _id, id, displayName, email, password, mobileNo, role }
    EditUserAction(
      dispatch,
      editData,
      {
        onSuccess: (res) => {
          setState({ loading: false })
          if (res.success == true) {
            alert("User Updated Succesfully")
            setState({ loading: true, Editmode: false })
            fetchUseraction(dispatch, {
              onSuccess: () => { setState({ loading: false }) },
              onFailure: () => { setState({ loading: false }) }
            });
          }
        },
        onFailure: () => {
          setState({ loading: false })
          alert("User Not Updated")
        },
      }
    );
  };
  const saveNewUser = () => {
    let displayName = state.name;
    let email = state.email;
    let mobileNo = state.phonenumber;
    let password = state.password;
    var newUserData = {
      displayName,
      mobileNo,
      email,
      password
    }
    setState({ loading: true })
    adduserAction(
      dispatch,
      newUserData,
      {
        onSuccess: (res) => {
          setState({ loading: false })
          if (res.status == 200) {
            if (res.data.success == true) {
              setState({ Editmode: false, loading: true });
              fetchUseraction(dispatch, {
                onSuccess: () => { setState({ loading: false }) },
                onFailure: () => { setState({ loading: false }) }
              });
            }
          }
        },
        onFailure: () => {
          setState({ loading: false })
          alert("Something went wrong")
        },
      }
    );
  };

  const decryptPassword = (pwd) => {
    var key = 10

    var decryted_text = ''
    for (let i = 0; i < pwd.length; i++) {
      decryted_text += String.fromCharCode(pwd[i].charCodeAt(0) - key)
    }
    return decryted_text
  }


  var data = state.usersList;


  var columns = [
    {
      Header: "Name",
      accessor: "displayName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone Number",
      accessor: "mobileNo",
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 60,
      Cell: (row) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          <Popup
            content="Change Password"
            trigger={
              <Icon
                name="key"
                onClick={() => changePasswordDetails(row.original)}
                style={{
                  width: 20,
                  align: "center",
                  cursor: "pointer",
                  color: "#F2711C",
                }}
              />
            }
          />
          <Popup
            content="Edit User"
            trigger={
              <Icon
                name="pencil alternate"
                size="large"
                onClick={() => editUserDetail(row.original)}
                style={{
                  width: 20,
                  align: "center",
                  cursor: "pointer",
                  color: "#21BA45",
                }}
              />
            }
          />
          <Popup
            content="Delete User"
            trigger={
              <Icon
                name="trash alternate outline"
                size="large"
                onClick={() => deleteUser(row.original)}
                style={{
                  width: 20,
                  align: "center",
                  cursor: "pointer",
                  color: "#DB2828",
                }}
              />
            }
          />
        </div>
      )
    }
  ];
  return (
    <div style={{ height: "100%", backgroundColor: "white" }}>
      <Modal open={state.loading}>
        <Loader>Loading</Loader>
      </Modal>
      <div style={{ width: "95vw" }}>
        <h1
          style={{
            paddingLeft: 30,
            width: "500px",
            flex: "0 0 30px",
            display: "inline-block",
            color: "teal",
          }}
        >
          User Details
        </h1>

        <Label
          style={{ cursor: "pointer", float: 'right' }}
          size="large"
          color="blue"
          onClick={addNewUser}
        >
          <Icon name="user plus" />
          Add User
        </Label>

        <div style={{ display: "flex", overflowX: "scroll" }}>
          <ReactTable
            noDataText="We couldn't find anything"
            filterable={true}
            defaultPageSize={10}
            sortable={true}
            style={{ height: "72vh", width: "94vw", marginLeft: 30 }}
            columns={columns}
            data={data}
          />
        </div>

        <Modal
          open={state.Editmode}
          onClose={closeModal}
          size="small"
        >
          <Modal.Content>
            <Form>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Name"
                      value={state.name}
                      onChange={(e) => Namechange(e)}
                      placeholder="your Name"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email"
                      value={state.email}
                      onChange={(e) => Emailchange(e)}
                      placeholder="your Email"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="any"
                      label="Phone Number"
                      value={state.phonenumber}
                      onChange={(e) => Numberchange(e)}
                      placeholder="your phonenumber"
                    />
                  </Grid.Column>
                  {state.editedData == false &&
                    <Grid.Column style={{ marginTop: 20 }}>
                      <Form.Input
                        type="any"
                        label="Password"
                        value={state.password}
                        onChange={(e) => passwordChange(e)}
                        placeholder="your password"
                      />
                    </Grid.Column>
                  }
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            {state.Editmode &&
              <Button
                color="green"
                onClick={
                  state.editedData ? edituser : saveNewUser
                }
              >
                Save
              </Button>
            }
          </Modal.Actions>
        </Modal>
        <Modal
          open={state.changePassMode}
          onClose={closeChangeModal}
          size="mini"
        >
          <Modal.Header>
            Change Password
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Grid columns={1}>
                {/* <Grid.Row>
                    <Grid.Column style={{ marginTop: 10 }}>
                      <Form.Input
                        type="text"
                        label="old Password"
                        disabled={state.changePassMode}
                        value={decryptPassword(state.changeData?.password ?? "")}
                        // onChange={(e) => passwordChange(e)}
                        placeholder="Password"
                      />
                    </Grid.Column>
                  </Grid.Row> */}
                <Grid.Row>
                  <Grid.Column style={{ marginTop: 10 }}>
                    <Form.Input
                      type="text"
                      label="New Password"
                      // disabled={state.editedData}
                      value={state.password}
                      onChange={(e) => passwordChange(e)}
                      placeholder="Password"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeChangeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="black"
              onClick={changePassword}
            >
              Change Password
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={state.deleteMode}
          onClose={closeDeleteModal}
          size="mini"
        >
          <Modal.Header>
            Delete User
          </Modal.Header>
          <Modal.Description>
            <div style={{ marginLeft: 30 }}>
              <p>   </p> Are you sure want to delete this user
              <p>   </p> Name : {state.deleteData?.displayName ?? ""} ,<br />
              <p>   </p> Email : {state.deleteData?.email ?? ""} ,<br />
            </div>
          </Modal.Description>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="black" onClick={closeDeleteModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="red"
              onClick={deleteUserNext}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
}

export default Users;

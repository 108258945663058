import moment from 'moment';
import axios from 'axios';


export const setupAxios = (accessToken = "") => {
    if (accessToken == 'expired' || accessToken.length < 10) {
        delete axios.defaults.headers.common['Authorization'];
    }
    else if (accessToken.length > 10) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
    }
}

export const isAdmin = user => {
    return user.role == "Admin";
};

export function isLoggedIn(authState) {
    try {
        if (!authState.accessToken || authState?.accessToken == "expired")
            return false;
        if (!authState.isLoggedIn)
            return false;

        if (moment(new Date(authState.expiresIn + "000")).isBefore(moment())) {
            return false;
        }

        return true;
    } catch (error) {
        return false;
    }
}

export function getPageSize(count) {
    if (count == 0)
        return 10;
    if (count >= 50)
        return 50;
    return Math.round(count / 10) * 10
}

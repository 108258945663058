import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import { loginAction, forgotPassword } from "../actions/index";

import { isLoggedIn } from "../util";
import matrix from "../Images/matrix-logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const [state, setStateAction] = useState({
    username: "",
    password: "",
    email: false,
    EmailId: "",
    platform: "Web",
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }
  useEffect(() => {
    console.log("Login",authState)
    if (isLoggedIn(authState)) {
      navigate('/home');
    }
  })


  const handleUsernameChange = (e) => {
    setState({ username: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setState({ password: e.target.value });
  };

  const authenticate = () => {
    loginAction(
      dispatch,
      state.username,
      state.password,
      state.platform
    );
  };

  const email = () => {
    setState({
      email: true,
    });
  };

  const Close = () => {
    setState({
      email: false,
    });
  };

  const SendEmail = () => {
    let email = this.state.EmailId;
    let data = {
      loginId: email,
    };
    forgotPassword(dispatch, data);
  };
  const handleEmail = (e) => {
    setState({
      EmailId: e.target.value,
    });
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 500,padding: 50, borderRadius: "15px",boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
        {/* <img src={matrix} style={{ height: "100px", width: "180px" }} /> */}
        <Form className="auth-inner">
          <img src={matrix} style={{ height: "100px", width: 'auto', marginBottom: '10px' }} />

          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
            onChange={handleUsernameChange}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            onChange={handlePasswordChange}
          />

          <Button color="blue" fluid size="large" onClick={authenticate}>
            Login
          </Button>
        </Form>
      </Grid.Column>
      {state.email === true && (
        <Modal
          size="mini"
          dimmer="blurring"
          open={state.email}
          onClose={Close}
        >
          <Modal.Header>Enter LoginId</Modal.Header>
          <Modal.Content>
            <Form>
              <Input
                type="email"
                iconPosition="left"
                style={{ width: 300 }}
                placeholder="Enter the Email"
                onChange={handleEmail}
              >
                <Icon name="at" />
                <input />
              </Input>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={Close}>
              Cancel
            </Button>
            <Button color="green" onClick={SendEmail}>
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Grid>
  );
}

export default Login;

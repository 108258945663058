import React, { useRef, useState, useEffect } from "react";
import {
  Icon,
  Segment,
  Button,
  Grid,
  Dropdown,
  Input,
  Sidebar,
  Menu,
  Container,
  Loader,
  Modal,
  Image,
} from "semantic-ui-react";
import { isAdmin, isLoggedIn } from "../../util";
import { submitVendorAction, saveVendorAction, getVendorCategoryAction } from "../../actions/vendor_actions";
import ReactSignatureCanvas from "react-signature-canvas";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const majorCustomerid = "62d913bc05ae37964ed49036"

const VendorCreationForm = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const signPadRef = useRef();
  const authState = useSelector((state) => state.auth)
  const isLoggedInUser = isLoggedIn(authState)
  const [state, setStateAction] = useState({
    EditView: false,
    disabled: false,
    submitModal: false,
    submitConfirmationModal: false,
    submitted: false,
    loading: false,
    sideBarVisible: true,
    questionSections: [],
    selectedForms: [],
    selectedFormIndex: 0,
    validityArray: [],
    isHasMajorCustomers: false,
    majorCustomerQuestions: [],
    imageModal: false,
    vendorID: "",
    activeItem: "",
    signPadModalOpen: false,
    signatureData: "",
    signatureQuestion: {},
    signatureIndex: 0,
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }

  useEffect(() => {
    var vendorID = params.id
    console.log(vendorID, "vendor id")
    setState({ vendorID, loading: true })
    getVendorCategoryAction(dispatch, vendorID, {
      onSuccess: (data) => {
        console.log("Get Category Data", data)
        var selectedFormsTemplate = JSON.parse(JSON.stringify(data.data))
        var EditView = false
        var disabled = data.isSubmitted
        var selectedForms = data.data
        if (isLoggedIn(authState)) {
          disabled = true
        }
        setState({ loading: false, submitted: data.isSubmitted, EditView, disabled, selectedForms, selectedFormsTemplate, activeItem: selectedFormsTemplate[0].categoryId, selectedFormIndex: 0 })
        var isMajorCustomer = selectedFormsTemplate.findIndex((fitem) => (fitem.categoryId == majorCustomerid))
        if (isMajorCustomer > 0) {
          setState({ majorCustomerQuestions: JSON.parse(JSON.stringify(selectedFormsTemplate[isMajorCustomer].questions)) })
        }
      },
      onFailure: (errorMsg) => {
        console.log("Get Category Failure")
        setState({ loading: false, disabled: true, EditView: false })
        alert(errorMsg)
        // back()
      }
    })
  }, [])

  const cancel = () => {
    setState({
      EditView: false,
      disabled: false,
    });
  };
  const closeSubmit = () => {
    setState({
      submitModal: false,
      submitConfirmationModal: false
    });
  };
  const handleItemClick = (sectionItem, sectionIndex) => {
    console.log(sectionItem.category)
    setState({ activeItem: sectionItem.categoryId, selectedFormIndex: sectionIndex })
  }
  const back = () => {
    if (isAdmin(authState)) {
      navigate("/controlpanel");
    }
    else if (isLoggedInUser) {
      navigate("/home");
    }
  };
  const changeAnswer = (question, e) => {
    const { selectedFormIndex, selectedForms } = state
    let answer = "";
    var questionsArray = selectedForms[selectedFormIndex].questions;

    if (question.answerType === "text") {
      answer = e.target.value;
      questionsArray.map((q) => {
        if (q.id == question.id) {
          q.answer = answer;
        }
      });
    }
    if (question.answerType === "signPad") {
      answer = e;
      questionsArray.map((q) => {
        if (q.id == question.id) {
          q.answer = answer;
        }
      });
    }
    if (question.answerType === "dropdown") {
      answer = e;
      questionsArray.map((q) => {
        if (q.id == question.id) {
          q.answer = answer;
        }
      });
    }
    var newselectedForms = selectedForms
    newselectedForms[selectedFormIndex].questions = questionsArray
    setState({
      selectedForms: newselectedForms,
    });
    checkAllAnsers()
  };

  const addNewCustomer = () => {
    const { selectedFormIndex, selectedForms, majorCustomerQuestions } = state
    var selectedQuestion = selectedForms[selectedFormIndex];
    var newQuesions = JSON.parse(JSON.stringify(majorCustomerQuestions))
    if (selectedQuestion.hasOwnProperty('extraQuestions')) {
      selectedQuestion.extraQuestions.push({ questions: newQuesions })
    }
    else {
      selectedQuestion.extraQuestions = []
      selectedQuestion.extraQuestions.push({ questions: newQuesions })
    }
    var newselectedForms = selectedForms
    newselectedForms[selectedFormIndex] = selectedQuestion
    setState({
      selectedForms: newselectedForms,
    });
  }
  const removeCustomer = (customerIndex) => {
    const { selectedFormIndex, selectedForms } = state
    var selectedQuestion = selectedForms[selectedFormIndex];
    if (selectedQuestion.hasOwnProperty('extraQuestions') && selectedQuestion.extraQuestions.length > 1) {
      selectedQuestion.extraQuestions.splice(customerIndex, 1)
    }
    else {
      delete selectedQuestion.extraQuestions
    }
    var newselectedForms = selectedForms
    newselectedForms[selectedFormIndex] = selectedQuestion
    setState({
      selectedForms: newselectedForms,
    });
  }
  const changeCustomerAnswer = (question, index, e) => {
    const { selectedFormIndex, selectedForms } = state
    let answer = "";
    var extraQuestions = selectedForms[selectedFormIndex].extraQuestions;
    var questionsArray = extraQuestions[index].questions
    if (question.answerType === "text") {
      answer = e.target.value;
      questionsArray.map((q) => {
        if (q.id == question.id) {
          q.answer = answer;
        }
      });
    }
    var newselectedForms = selectedForms
    newselectedForms[selectedFormIndex].extraQuestions[index].questions = questionsArray
    setState({
      selectedForms: newselectedForms,
    });
    checkAllAnsers()
  };

  const checkAllAnsers = () => {
    const { selectedFormIndex, selectedForms } = state
    var validityArray = []
    selectedForms.map((sitem, sindex) => {
      var isValid = true
      sitem.questions.map((qitem, qindex) => {
        if (qitem.mandatory == true) {
          if (!qitem.answer) isValid = false
          else if (qitem.answerType == "text") {
            if (qitem.answer.trim() == "") {
              isValid = false
            }
          }
        }
      })
      validityArray.push(isValid)
    })
    setState({ validityArray })
    // console.log(validityArray, "Validity Array")
  }
  const submitConfirmation = () => {
    setState({ submitConfirmationModal: true })
  }
  const submitForm = () => {
    const { selectedForms, vendorID } = state
    console.log("Submit")
    console.log(selectedForms)
    setState({ loading: true })
    submitVendorAction(dispatch, vendorID, selectedForms, {
      onSuccess: (data) => {
        console.log("Submit success")
        console.log(data)
        // alert("Submit success")
        setState({ submitModal: true, submitted: true, disabled: true, loading: false })
        // back()
      },
      onFailure: () => {
        console.log("Submit failed")
        setState({ loading: false })
        alert("Submit failed")
      }
    })
  };

  const saveForm = () => {
    const { selectedForms, vendorID } = state
    console.log("Save")
    console.log(selectedForms)
    setState({ loading: true })
    saveVendorAction(dispatch, vendorID, selectedForms, {
      onSuccess: (data) => {
        console.log(data)
        alert(data.message ?? "Save success")
        setState({ loading: false })
        // back()
      },
      onFailure: () => {
        console.log("Save failed")
        setState({ loading: false })
        alert("Save failed")
      }
    })
  };

  const handleSignPadOpenButton = (action, question, questionIndex) => {
    if (action === 'Open') {
      setState({ signPadModalOpen: true, signatureQuestion: question, signatureIndex: questionIndex })
    }
    else if (action === 'Close') {
      setState({ signPadModalOpen: false })
    }
    else if (action === 'Save') {
      const empty_signpad = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2NgAAIAAAUAAR4f7BQAAAAASUVORK5CYII='
      const signPad = signPadRef.current.getTrimmedCanvas().toDataURL("image/png")
      if (empty_signpad !== signPad) {
        setState({ signatureData: signPad, signPadModalOpen: false })
        changeAnswer(state.signatureQuestion, signPad)
      }
      else {
        signPadRef.current.clear()
        setState({ signatureData: "" })
      }
    }
    else if (action === 'Clear') {
      signPadRef.current.clear()
    }

  }

  const { activeItem, validityArray, selectedForms, selectedFormIndex, sideBarVisible, disabled, submitted } = state
  var pusherStyle = {
    height: "100vh",
    backgroundColor: "wheat",

    overflow: "auto",
    width: "90%",
    border: 0,
  };
  if (!sideBarVisible) {
    pusherStyle.width = "100%";
  }
  var selectedCurrentForm = selectedForms[selectedFormIndex]
  var isMajorCustomer = activeItem == majorCustomerid
  return (

    <div style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: 'white' }}>
      <Modal open={state.loading}>
        <Loader>Loading</Loader>
      </Modal>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          margin: '10px 0px 0px 10px',
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            flexDirection: "row",
            margin: 0
          }}
        >

          <Icon
            style={{
              display: "inline-block",
              cursor: "pointer",
              float: "left",
              color: "brown",
            }}
            onClick={() => { setState({ sideBarVisible: !sideBarVisible }) }}
            size={"big"}
            name={sideBarVisible ? "outdent" : "indent"}
          />
          <h4
            style={{
              marginLeft: 30,
              marginTop: 0,
              color: "brown",
              display: "inline-block",
              fontSize: 25,
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Vendor Registration Form
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            // flex: 1,
            alignItems: "center",
            flexDirection: "row",
            margin: 0
          }}
        >
          {!submitted && !disabled &&
            <div>
              <Button
                color="green"
                circular
                disabled={state.validityArray.length == 0 || state.validityArray.some((item) => item == false)}
                onClick={submitConfirmation}
              >
                <Icon name="check circle" />  Submit
              </Button>

            </div>
          }
          {!submitted && !disabled &&
            <div>
              <Button
                color="blue"
                circular
                style={{ marginLeft: 10 }}
                onClick={saveForm}
              >
                <Icon name="save outline" />  Save
              </Button>

            </div>
          }
          {isLoggedInUser ?
            <Button
              color="red"
              circular
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={back}
            >
              <Icon name="close" />  Close
            </Button>
            :
            <div style={{ marginRight: 10 }}></div>
          }
        </div>
      </div>

      <Sidebar.Pushable
        as={Segment}
        style={{
          // marginTop: -25,
          display: "flex",
          borderRadius: 0,
          height: "calc(100% - 70px)",
        }}
      >
        <Sidebar
          as={Menu}
          visible={sideBarVisible}
          activeIndex="0"
          style={{
            flex: "0 0 150px",
            backgroundColor: "#05386B",
            paddingTop: 5,
          }}
          animation="slide along"
          width="thin"
          icon="labeled"
          vertical
          inverted
        >
          <div>
            {selectedForms.map((sectionItem, sectionIndex) => {
              var isActive = (activeItem === sectionItem.categoryId)
              var isInvalid = validityArray[sectionIndex] == false
              return (
                <Menu.Item
                  key={sectionIndex}
                  name={sectionItem.category}
                  active={isActive}
                  color="blue"
                  onClick={() => handleItemClick(sectionItem, sectionIndex)}
                  style={{ marginTop: 10, fontWeight: 'normal', fontSize: '13px' }}
                >
                  {isInvalid &&
                    <span style={{ color: "red", fontSize: '20px', position: "absolute", alignSelf: 'center', paddingLeft: 15 }}>*</span>
                  }
                  <Icon name="wpforms" color={isActive ? undefined : "teal"} />
                  <span style={{ color: isActive ? "white" : "gray" }}>{sectionItem.category}</span>

                </Menu.Item>
              )
            })
            }
          </div>
        </Sidebar>
        <Sidebar.Pusher style={pusherStyle}>
          <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
            <Segment style={{ width: "95vw", marginBottom: "5vh", marginTop: '2vh', marginRight: '1vw', marginLeft: '1vw', borderRadius: 10, padding: 20, }}>
              {isMajorCustomer && !disabled &&
                <Grid>
                  <Grid.Row style={{ justifyContent: "flex-end", alignItems: 'center' }}>
                    <Button
                      color="blue"
                      circular
                      onClick={addNewCustomer}
                    >
                      <Icon name="plus" />  Add Customer
                    </Button>
                  </Grid.Row>
                </Grid>
              }
              <Grid >
                <Grid.Row >
                  {selectedCurrentForm?.questions != null &&
                    selectedCurrentForm?.questions != undefined &&
                    selectedCurrentForm?.questions.length > 0 &&
                    selectedCurrentForm?.questions.map((ques, quesIndex) => (
                      <React.Fragment key={quesIndex}>
                        {ques.answerType === "text" && (
                          <React.Fragment>

                            <Grid.Column width={4}>
                              <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                {ques.question} {ques.mandatory == true && <span style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>*</span>}
                              </span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              {" "}
                              <Input
                                value={ques.answer ?? ""}

                                placeholder='Enter text'
                                onChange={(e) => changeAnswer(ques, e)}
                                disabled={disabled}
                                style={{ width: "100%", marginBottom: 15 }}
                              />{" "}
                            </Grid.Column>
                          </React.Fragment>

                        )}
                        {ques.answerType === "signPad" && (
                          <React.Fragment>
                            <Grid.Column width={4}>
                              <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                {ques.question} {ques.mandatory == true && <span style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>*</span>}
                              </span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Container style={{ marginTop: 1, paddingLeft: 2, paddingRight: 2, marginBottom: 15 }}>
                                <Button disabled={disabled} size="small" onClick={() => { handleSignPadOpenButton('Open', ques, quesIndex) }}>Open Sign Pad</Button>
                                <Image
                                  size="medium"
                                  bordered
                                  rounded
                                  centered
                                  spaced='left'
                                  src={ques.answer ?? ""}
                                  alt='Signature Not Available'
                                />
                              </Container>

                            </Grid.Column>
                          </React.Fragment>
                        )}
                        {ques.answerType === "dropdown" && (
                          <React.Fragment>
                            <Grid.Column width={4}>
                              {" "}
                              <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                {ques.question} {ques.mandatory == true && <span style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>*</span>}
                              </span>{" "}
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Dropdown
                                options={ques.options.map((label, i) => {
                                  return {
                                    value: label.value,
                                    text: label.label,
                                    key: label.value,
                                  };
                                })}
                                onChange={(e, { value }) =>
                                  changeAnswer(ques, value)
                                }
                                style={{ width: "100%", marginBottom: 15 }}
                                value={ques.answer ?? null}
                                selection
                                fluid
                                disabled={disabled}
                                placeholder={"Select any option"}
                              />{" "}
                            </Grid.Column>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                </Grid.Row>
              </Grid>
              {isMajorCustomer == true && selectedCurrentForm?.extraQuestions != null &&
                selectedCurrentForm?.extraQuestions != undefined &&
                selectedCurrentForm?.extraQuestions.length > 0 &&
                selectedCurrentForm?.extraQuestions.map((exques, exquesIndex) => (
                  <div key={exquesIndex} style={{ marginTop: 20, paddingTop: 20, paddingBottom: 5, borderTop: "1px dashed grey" }}>
                    <Grid >
                      <Grid.Row>
                        {exques.questions.map((ques, quesindex) => (
                          <React.Fragment key={quesindex}>

                            {ques.answerType === "text" && (
                              <React.Fragment>
                                <Grid.Column width={4}>
                                  <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                    {ques.question} {ques.mandatory == true && <span style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>*</span>}
                                  </span>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                  {" "}
                                  <Input
                                    value={ques.answer ?? ""}
                                    placeholder='Enter text'
                                    disabled={disabled}
                                    onChange={(e) => changeCustomerAnswer(ques, exquesIndex, e)}
                                    style={{ width: "100%", marginBottom: 15 }}
                                  />{" "}
                                </Grid.Column>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid.Row>
                    </Grid>
                    {!disabled &&
                      <Grid>
                        <Grid.Row style={{ justifyContent: "flex-end", alignItems: 'center' }}>
                          <Button
                            color="red"
                            circular
                            onClick={() => removeCustomer(exquesIndex)}
                          >
                            <Icon name="minus" />  Remove
                          </Button>
                        </Grid.Row>
                      </Grid>
                    }
                  </div>
                ))}
            </Segment>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>



      <Modal
        open={state.submitModal}
        onClose={closeSubmit}
        size="mini"
      >
        <Modal.Header>
          Submitted
        </Modal.Header>
        <Modal.Content>
          Form has been submitted successfully
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="green" onClick={closeSubmit}>
            <Icon name="check" /> Okay
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={state.signPadModalOpen}
        onClose={() => { handleSignPadOpenButton('Close') }}
        size="small"
      >
        <Modal.Header>
          Signature
        </Modal.Header>
        <Modal.Content>
          <ReactSignatureCanvas
            penColor='black'
            ref={signPadRef}
            canvasProps={{ width: '900px', height: '200px', border: '1px solid black', className: 'sigCanvas' }}
          />
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="red" onClick={() => { handleSignPadOpenButton('Clear') }}>
            <Icon name="close" /> Clear
          </Button>
          <Button color="black" onClick={() => { handleSignPadOpenButton('Close') }}>
            <Icon name="trash" /> Close
          </Button>
          <Button color="green" onClick={() => { handleSignPadOpenButton('Save') }}>
            <Icon name="check" /> Save
          </Button>

        </Modal.Actions>
      </Modal>

      <Modal
        open={state.submitConfirmationModal}
        onClose={closeSubmit}
        size="mini"
      >
        <Modal.Header>
          Submit Vendor
        </Modal.Header>
        <Modal.Content>
          Are you want to Submit this Vendor Registration Form
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="black" onClick={closeSubmit}>
            Cancel
          </Button>
          <Button color="green" onClick={submitForm}>
            <Icon name="check circle outline" /> Submit
          </Button>
        </Modal.Actions>
      </Modal>

    </div >
  );
}

export default VendorCreationForm;

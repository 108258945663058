import React, { useEffect, useState } from "react";
import { isLoggedIn } from "./../../util";
import {
  Icon,
  Label,
  Modal,
  Grid,
  Form,
  Loader,
  Dropdown,
  Button,
  Tab,
  Confirm,
  List,
  Popup,
  Input,
  Radio,
} from "semantic-ui-react";
import {
  // fetchVendorsQuestionaction,
  fetchVendorsQuestionSectionaction,
  fetchVendorsaction,
  addVendorAction,
  editVendorAction,
  deleteVendorAction,
  startVendorAction,
  renewalVendorAction,
  editVendorFormAction,
  // fetchAuditQuestionaction,
  fetchAuditQuestionSectionaction,
  startAuditAction,
  editAuditAction,
  approveVendorAction,
  downloadVendorsList,
  downloadConsolidatedVendorReport,
  downloadVendorFormPDF
} from "../../actions/vendor_actions.js";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Stack, TextField } from "@mui/material";
import ReactTable from "react-table-v6";
import FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../actions";



const majorCustomerid = "62d913bc05ae37964ed49036"
const Vendors = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const vendorState = useSelector((state) => state.vendor)
  const [state, setStateAction] = useState({
    createModal: false,
    isEditVendor: false,
    startModal: false,
    startAuditModal: false,
    approveModal: false,
    viewFormModal: false,
    historyView: false,
    selectedHistory: [],
    startAuditData: {},
    startData: {},
    editData: {},
    deleteData: {},
    approveData: {},
    viewFormData: {},
    loading: false,
    vendorList: [
      // { _id: "0", address: "", firmName: "", location: "", process: "", url: "" },
    ],
    questionSections: [],
    questionSectionsOptions: [],
    auditSectionsOptions: [],
    selectedForms: [],
    auditForms: [],
    email: "",
    location: "",
    firmName: "",
    process: "",
    address: "",
    url: "",
    isRegistered: true,
    expiryDateTime: "",
    showMessage: false,
    responseMessageForAddVendor: '',
    cc: "",
    showFormsField: true,
    showRenewal: false,
    rejectReason: '',
    dateTypeList: [
      { key: 'initiated', value: 'registrationInitiatedAt', text: 'Initiated' },
      { key: 'submitted', value: 'registrationSubmittedAt', text: 'Submitted' }
    ],
    dateType: "registrationInitiatedAt",
    startDate: "",
    endDate: ""
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }

  useEffect(() => {
    getVendors()
    getVendorQuestionSections()
    getAuditQuestionSections()
  }, [])

  const getVendors = () => {
    setState({ loading: true })
    fetchVendorsaction(
      dispatch,
      {
        onSuccess: (data) => { setState({ loading: false }) },
        onFailure: () => { setState({ loading: false }) }
      });
  }
  const getVendorQuestionSections = () => {
    setState({ loading: true })
    fetchVendorsQuestionSectionaction(
      dispatch,
      {
        onSuccess: (data) => { setState({ loading: false, questionSectionsOptions: data }) },
        onFailure: () => { setState({ loading: false }) }
      });
  }
  const getAuditQuestionSections = () => {
    setState({ loading: true })
    fetchAuditQuestionSectionaction(
      dispatch,
      {
        onSuccess: (data) => { setState({ loading: false }) },
        onFailure: () => { setState({ loading: false }) }
      });
  }

  useEffect(() => {
    var data = vendorState.allvendors
    var questionSectionsData = vendorState.questionSections
    var auditquestionSectionData = vendorState.auditquestionSections
    //Vendor Data
    if (data) {
      console.log("Vendor DATA", data)
      setState({ vendorList: data })
    }
    //Question Data
    if (questionSectionsData) {
      console.log("Question DATA", questionSectionsData)
      setState({ questionSectionsOptions: JSON.parse(JSON.stringify(questionSectionsData)) })
    }
    //Audit Data
    if (auditquestionSectionData) {
      console.log("Audit DATA", auditquestionSectionData)
      setState({ auditSectionsOptions: JSON.parse(JSON.stringify(auditquestionSectionData)) })
    }
  }, [vendorState.allvendors, vendorState.questionSections, vendorState.auditquestionSections])

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
      navigate(`/login`, { replace: true });
    }
  }, [authState.isLoggedIn])

  const emailChange = (e) => {
    setState({
      email: e.target.value,
    });
  };

  const locationChange = (e) => {
    setState({
      location: e.target.value,
    });
  };
  const firmNameChange = (e) => {
    setState({
      firmName: e.target.value,
    });
  };
  const processChange = (e) => {
    setState({
      process: e.target.value,
    });
  };
  const addressChange = (e) => {
    setState({
      address: e.target.value,
    });
  };
  const urlChange = (e) => {
    setState({
      url: e.target.value,
    });
  };
  const expiryDateTimeChange = (newValue) => {
    console.log(newValue)
    if (newValue) {
      if (newValue._isValid == true) {
        console.log(newValue._d, "Date")
        setState({ expiryDateTime: newValue._d })
      }
    }
  }

  const createNewVendor = () => {
    setState({
      createModal: true,
      isEditVendor: false,
      location: "",
      firmName: "",
      process: "",
      address: "",
      url: "",
      email: "",
    });
  };
  const editVendor = (data) => {
    setState({
      createModal: true,
      isEditVendor: true,
      editData: data,
      location: data.location,
      firmName: data.firmName,
      process: data.process,
      address: data.address,
      url: data.url,
      email: data.email,
    });
  };
  const approveVendor = (data) => {
    console.log(data, "appro")
    setState({
      approveModal: true,
      approveData: data,
      email: data.email,
    });
  };
  const deleteVendor = (data) => {
    setState({
      deleteModal: true,
      deleteData: data
    })
  }
  const viewVendorFormStatus = (data) => {
    setState({
      viewFormData: data,
      viewFormModal: true
    })
  }
  const closeModal = () => {
    setState({
      createModal: false,
      isEditVendor: false,
      startModal: false,
      startAuditModal: false,
      deleteModal: false,
      approveModal: false,
      viewFormModal: false,
      historyView: false
    });
  };


  const viewVendorFormHistory = (data) => {
    let selectedHistory = data['vendorStatusHistory'] ?? []
    console.log(selectedHistory, data)
    setState({ selectedHistory: selectedHistory, historyView: true })
  }


  const saveNewVendor = () => {
    const { location, firmName, process, address, url, email } = state
    var newVendorData = {
      location,
      firmName,
      process,
      address,
      url,
      email
    }
    console.log(newVendorData, "New Vendor Data")
    setState({ loading: true })
    addVendorAction(
      dispatch,
      newVendorData,
      {
        onSuccess: (data) => {
          console.log(data, "New Vendor Data Successfully")
          if (data && data.success) {
            setState({ loading: false, createModal: false, responseMessageForAddVendor: data.message, showMessage: true })
            getVendors()
          } else {
            setState({ loading: false, createModal: false, responseMessageForAddVendor: data.message, showMessage: true })
          }

        },
        onFailure: () => { setState({ loading: false, createModal: false }) }
      });

  };

  const saveEditVendor = () => {
    const { location, firmName, email, process, address, url, editData } = state
    var newVendorData = {
      location,
      firmName,
      process,
      address,
      url,
      email
    }
    var newEditData = JSON.parse(JSON.stringify(editData))
    var editVendorData = Object.assign(newEditData, newVendorData)
    editVendorData['id'] = editVendorData['_id']
    console.log(editVendorData, "New Vendor Data")
    setState({ loading: true })
    editVendorAction(
      dispatch,
      editVendorData,
      {
        onSuccess: (data) => {
          setState({ loading: false, createModal: false, isEditVendor: false })
          getVendors()
        },
        onFailure: () => {
          setState({ loading: false })
          alert("Something went wrong not updated")
          // setState({ createModal: false,isEditVendor:false })
        }
      });

  };
  const saveDeleteVendor = () => {
    const { deleteData } = state

    console.log(deleteData, "Delete Vendor Data")
    setState({ loading: true })
    deleteVendorAction(
      dispatch,
      deleteData,
      {
        onSuccess: (data) => {
          setState({ loading: false, deleteModal: false })
          getVendors()
        },
        onFailure: () => { setState({ loading: false, deleteModal: false }) }
      });

  };

  const approveOrReject = (data) => {
    return approveVendorAction(
      dispatch,
      data,
      {
        onSuccess: (data) => {
          setState({ loading: false, approveModal: false, rejectionReason: "", expiryDateTime: "", mailCC: '', showFormsField: true, showRenewal: false, startModal: false })
          getVendors()
        },
        onFailure: () => { setState({ loading: false, approveModal: false }) }
      });
  }

  const rejectForm = () => {
    const { approveData } = state
    let data = {
      id: approveData._id,
      isApproved: false,
      rejectionReason: state.rejectReason,
      expiryDateTime: moment(state.expiryDateTime).format("DD/MM/YYYY HH:mm:ss"),
      emailId: state.email,
      mailCC: state.cc
    }
    approveOrReject(data)

  }

  const saveApproveVendor = (isApproved) => {
    const { approveData } = state
    if (!isApproved) {

      setState({ startModal: true, showFormsField: false, showRenewal: false })
      // approveOrReject(data)
    } else {
      let data = {
        id: approveData._id,
        isApproved,
      }
      approveOrReject(data)

    }

  }

  const startFormDeatils = (data) => {
    console.log(data, "Start form data")
    setState({ startModal: true, email: data.email, expiryDateTime: "", startData: data, selectedForms: [], showFormsField: true, showRenewal: false })
  }
  const renewLinkFormDeatils = (data) => {
    console.log(data, "Renew Link form data")
    setState({ startModal: true, email: data.email, expiryDateTime: "", startData: data, selectedForms: [], showFormsField: false, showRenewal: true })
  }
  const startAuditDetails = (data) => {
    console.log(data, "Start Audit data")
    setState({ startAuditModal: true, email: data.email, expiryDateTime: "", startAuditData: data, auditForms: [] })
  }
  const editFormDetails = (data) => {
    console.log(data, "edit form data")
    const { majorCustomerQuestions } = state
    var editFormData = {
      vendorId: data._id,
      categoryList: data.data,
      majorCustomerQuestions
    }
    editVendorFormAction(dispatch, editFormData, { onSuccess: () => { }, onFailure: () => { } })
    navigate(`/registrationform/${data._id}`);
  }
  const editAuditDetails = (data) => {
    console.log(data, "edit audit data")
    const { majorCustomerQuestions } = state
    var editFormData = {
      vendorId: data._id,
      categoryList: data.data,
      majorCustomerQuestions
    }
    editAuditAction(dispatch, editFormData, { onSuccess: () => { }, onFailure: () => { } })
    navigate(`/auditform/${data._id}`);
  }
  const startAudit = () => {
    const { auditForms, email, expiryDateTime, startAuditData, cc } = state;
    var startAuditDetails = {
      vendorId: startAuditData._id,
      emailId: email,
      mailCC: cc,
      expiryDateTime: moment(expiryDateTime).format("DD/MM/YYYY HH:mm:ss"),
      initiatedSections: auditForms
    }
    console.log(startAuditDetails, "Start Audit Data")
    // return

    if (startAuditDetails.initiatedSections.length == 0) {
      alert("Please Select Audit")
      return
    }
    setState({ loading: true })
    startAuditAction(dispatch, startAuditDetails, {
      onSuccess: (data) => {
        console.log("Succes Start Audit", data)
        setState({ startAuditModal: false, loading: false, expiryDateTime: "" })
        getVendors()
        alert(data.message)
      },
      onFailure: (data) => {
        setState({ loading: false })
        alert(data)
      }
    })

  }

  const startForm = () => {
    const { selectedForms, email, expiryDateTime, startData, cc } = state;
    var startVendorData = {
      vendorId: startData._id,
      emailId: email,
      mailCC: cc,
      expiryDateTime: moment(expiryDateTime).format("DD/MM/YYYY HH:mm:ss"),
      initiatedSections: selectedForms
    }
    if (startVendorData.emailId.trim() == "") {
      alert("email is invalid")
      return
    }
    if (startVendorData.initiatedSections.length == 0) {
      alert("Please Select form")
      return
    }
    setState({ loading: true })
    startVendorAction(dispatch, startVendorData, {
      onSuccess: (data) => {
        console.log("Succes Start Form", data)
        setState({ startModal: false, loading: false, expiryDateTime: "" })
        getVendors()
        alert(data.message)
      },
      onFailure: (data) => {
        setState({ loading: false })
        alert(data)
      }
    })

  }

  const renewalForm = () => {
    const { email, expiryDateTime, startData, cc } = state;
    var renewVendorData = {
      vendorId: startData._id,
      emailId: email,
      mailCC: cc,
      expiryDateTime: moment(expiryDateTime).format("DD/MM/YYYY HH:mm:ss"),
    }
    console.log(renewVendorData)
    if (renewVendorData.emailId.trim() == "") {
      alert("email is invalid")
      return
    }
    setState({ loading: true })
    renewalVendorAction(dispatch, renewVendorData, {
      onSuccess: (data) => {
        console.log("Succes Start Form", data)
        setState({ startModal: false, loading: false, expiryDateTime: "" })
        getVendors()
        alert(data.message)
      },
      onFailure: (data) => {
        setState({ loading: false })
        alert(data)
      }
    })

  }

  const decryptPassword = (pwd) => {
    var key = 10

    var decryted_text = ''
    for (let i = 0; i < pwd.length; i++) {
      decryted_text += String.fromCharCode(pwd[i].charCodeAt(0) - key)
    }
    return decryted_text
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handleChangeForm = (e, data) => {
    console.log(data.value)
    setState({ selectedForms: data.value })

  }
  const handleChangeAudit = (e, data) => {
    console.log(data.value)
    setState({ auditForms: data.value })

  }

  function getRequestData() {
    let requestData = {
      dateType: state.dateType ?? "",
      filterType: 'all',
      startDate: state.startDate ?? "",
      endDate: state.endDate ?? ""
    }
    if (requestData.startDate != "" || requestData.endDate != "") {
      requestData['filterType'] = 'range'
    }
    return requestData
  }

  const downloadVendorList = async () => {
    try {
      setState({ loading: true })
      console.log("File available")
      let requestData = getRequestData()
      let apiResponse = await downloadVendorsList(requestData)
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, 'VendorList.xlsx')
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }

  const downloadCondolidatedVendorForm = async () => {
    try {
      setState({ loading: true })
      console.log("File available")
      let requestData = getRequestData()
      let apiResponse = await downloadConsolidatedVendorReport(requestData)
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, 'ConsolidatedVendorForms.xlsx')
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }

  const downloadVendorForm = async (rowData = {}) => {
    console.log("Download PDF", rowData)
    let vendorId = rowData?.['_id'] ?? ""
    try {
      setState({ loading: true })
      console.log("File available")
      let apiResponse = await downloadVendorFormPDF({ vendorId: vendorId })
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, `${vendorId}_VendorForms.pdf`)
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }

  const changeDates = (e, { value }) => setState({ [`${e?.target?.name ?? 'name'}`]: value })
  const { vendorList, questionSectionsOptions, auditSectionsOptions, isRegistered } = state
  const vendorTabs = [
    {
      menuItem: { key: "registered", icon: "thumbs up outline", content: "Registered" },
    },
    {
      menuItem: { key: "unregistered", icon: "clock outline", content: "Unregistered" },
    },

  ];
  var registerdVendorList = vendorList.filter((item) => item.isVendorApproved == true)
  var unRegisteredVendorList = vendorList.filter((item) => item.isVendorApproved != true)
  var columns = [
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Firm Name",
      accessor: "firmName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Process",
      accessor: "process",
    },
    {
      Header: "address",
      accessor: "address",
    },
    {
      Header: "URL",
      accessor: "url",
    },
  ]
  if (!isRegistered) {
    columns.push(
      {
        Header: "Status",
        accessor: "vendorStatus",
      },
      {
        Header: "Vendor",
        accessor: "startForm",
        minWidth: 100,
        Cell: (row) => (
          <div>
            {!state.startModal && (
              <div>
                {((row.original?.vendorStatus != undefined && row.original?.vendorStatus == "Registration Form Submitted") || row.original?.isVendorApproved == true) ?
                  (
                    <Button
                      onClick={() => editFormDetails(row.original)}
                      size='small'
                      color="blue"
                      style={{ alignItems: 'center', width: 130, alignSelf: 'center', }}
                    >
                      <Icon
                        name="eye"
                        size="large"
                        style={{ cursor: "pointer", color: "#FFF" }}
                      />
                      View
                    </Button>
                  )
                  :
                  <div>
                    {row.original?.vendorStatus == "Vendor Created" ?
                      (
                        <Button
                          onClick={() => startFormDeatils(row.original)}
                          size='small'
                          color="green"
                          style={{ alignItems: 'center', width: 130, alignSelf: 'center', }}
                        >
                          <Icon
                            name="play circle"
                            size="large"
                            style={{ cursor: "pointer", color: "#FFF" }}
                          />
                          Initiate
                        </Button>
                      )
                      :
                      <>
                        {
                          row.original?.vendorStatus == "Link Expired" ? (
                            <Button
                              onClick={() => renewLinkFormDeatils(row.original)}
                              size='small'
                              color="black"
                              style={{ alignItems: 'center', width: 130, alignSelf: 'center', }}
                            >
                              <Icon
                                name="gavel"
                                size="large"
                                style={{ cursor: "pointer", color: "#FFF" }}
                              />
                              Renew
                            </Button>
                          )
                            :
                            (
                              <Button
                                onClick={() => viewVendorFormStatus(row.original)}
                                size='small'
                                color="orange"
                                style={{ alignItems: 'center', width: 130, alignSelf: 'center', }}
                              >
                                <Icon
                                  name="stopwatch"
                                  size="large"
                                  style={{ cursor: "pointer", color: "#FFF" }}
                                />
                                In Progress
                              </Button>
                            )
                        }
                      </>
                    }
                  </div>
                }
              </div>
            )}
          </div>
        ),
      }
    )
  }

  if (isRegistered) {
    columns.push(
      {
        Header: "Audit",
        accessor: "startAudit",
        width: 130,
        textAlign: 'center',
        Cell: (row) => (
          <React.Fragment>
            {!state.startModal && (
              <div style={{ marginLeft: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {row.original?.isAuditInitiated != undefined && row.original?.isAuditInitiated == true ?
                  (
                    <Button
                      onClick={() => editAuditDetails(row.original)}
                      size='small'
                      disabled
                      color="grey"
                      style={{ alignItems: 'center', width: 130, alignSelf: 'center', }}
                    >
                      <Icon
                        name="stopwatch"
                        size="large"
                        style={{ cursor: "pointer", color: "#FFF" }}
                      />
                      In Progress
                    </Button>
                  )
                  :
                  (
                    <Button
                      onClick={() => startAuditDetails(row.original)}
                      size='small'
                      color="brown"
                      style={{ alignItems: 'center', width: 130, alignSelf: 'center', }}
                    >
                      <Icon
                        name="play circle"
                        size="large"
                        style={{ cursor: "pointer", color: "#FFF" }}
                      />
                      Initiate
                    </Button>
                  )}
              </div>
            )}
          </React.Fragment>
        )
      }
    )
  }
  columns.push(
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 130,
      Cell: (row) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          {isRegistered == false &&
            <Popup
              content="Approve / Reject Vendor Form"
              trigger={
                <Icon
                  name="check circle outline"
                  disabled={!(row.original?.vendorStatus != undefined && row.original?.vendorStatus == "Registration Form Submitted")}
                  size="large"
                  onClick={() => approveVendor(row.original)}
                  style={{
                    width: 30,
                    align: "center",
                    cursor: "pointer",
                    color: "#21BA45",
                  }}
                />
              }
            />
          }
          <Popup
            content="View Vendor Form"
            trigger={
              <Icon
                name="newspaper outline"
                size="large"
                onClick={() => editFormDetails(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "#2185D0",
                }}
              />}
          />
          <Popup
            content="Download Vendor Form"
            trigger={
              <Icon
                name="arrow alternate circle down outline"
                size="large"
                onClick={() => downloadVendorForm(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "purple",
                }}
              />}
          />
          <Popup
            content="History of Vendor Form "
            trigger={
              <Icon
                name="clock outline"
                size="large"
                loading
                onClick={() => viewVendorFormHistory(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "brown",
                }}
              />
            }
          />
          <Popup
            content="Edit Vendor Details"
            trigger={
              <Icon
                name="pencil alternate"
                size="large"
                onClick={() => editVendor(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "#21BA45",
                }}
              />
            }
          />
          <Popup
            content="Delete Vendor"
            trigger={
              <Icon
                name="trash alternate outline"
                size="large"
                onClick={() => deleteVendor(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "#DB2828",
                }}
              />
            }
          />
        </div>
      ),
    }
  )
  return (
    <div style={{ height: "100%", backgroundColor: "white" }}>
      <Modal open={state.loading}>
        <Loader>Loading</Loader>
      </Modal>
      <Confirm
        open={state.showMessage}
        content={state.responseMessageForAddVendor}
        onCancel={() => setState({ showMessage: false })}
        onConfirm={() => setState({ showMessage: false })}
        size="small"
      />
      <Tab panes={vendorTabs} menuPosition={'left'} onTabChange={(e, data) => {
        if (data.activeIndex == 0) {
          setState({ isRegistered: true })
        } else {
          setState({ isRegistered: false })
        }
      }} />
      <div style={{ width: "95vw", marginTop: 10 }}>
        <div style={{ alignContent: 'space-between' }}>
          <h1
            style={{
              paddingLeft: 30,
              width: '30vw',
              flex: "0 0 30px",
              display: "inline-block",
              color: "teal",
            }}
          >
            {isRegistered ? "Vendor Registered Details" : "Vendor Waiting Details"}
          </h1>

          {/* <br /> */}
          {!isRegistered ?
            <Label
              style={{ marginLeft: 30, cursor: "pointer", float: 'right' }}
              size="large"
              color="blue"
              onClick={createNewVendor}
            >
              <Icon name="user plus" />
              Add Vendor
            </Label>
            :
            <React.Fragment>
              {state.dateTypeList.map((item) => {
                return (
                  <Radio
                    key={item.key}
                    label={item.text}
                    value={item.value}
                    onChange={(e, d) => { setState({ 'dateType': d.value }) }}
                    style={{ marginRight: '10px' }}
                    checked={state.dateType == item.value}
                  />
                )
              })}
              <Input
                type="date"
                size="small"
                name='startDate'

                // value={state.startDate}
                label="Start Date"
                onChange={changeDates}
              />
              <Input
                type="date"
                size="small"
                name='endDate'
                label='End Date'
                style={{ marginLeft: '10px' }}
                // value={state.endDate}
                onChange={changeDates}
              />
              <Label
                style={{ marginLeft: 10, cursor: "pointer", float: 'right', marginTop: 8 }}
                size="large"
                color="blue"
                onClick={downloadVendorList}
              >
                <Icon name="download" />
                Vendors List
              </Label>
              <Label
                style={{ cursor: "pointer", float: 'right', marginTop: 8 }}
                size="large"
                color="blue"
                onClick={downloadCondolidatedVendorForm}
              >
                <Icon name="download" />
                Consolidated Vendors
              </Label>


            </React.Fragment>
          }
        </div>

        <div style={{ display: "flex", overflowX: "scroll" }}>
          <ReactTable
            noDataText="We couldn't find anything"
            filterable={true}
            defaultPageSize={10}
            sortable={true}
            style={{ height: "65vh", width: "94vw", marginLeft: 30 }}
            columns={columns}
            data={isRegistered ? registerdVendorList : unRegisteredVendorList}
          />
        </div>

        <Modal
          open={state.createModal}
          onClose={closeModal}
          size="small"
        >
          <Modal.Content>
            <Form>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email"
                      value={state.email}
                      // disabled={state.isEditVendor}
                      onChange={(e) => emailChange(e)}
                      placeholder="enter email"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Location"
                      value={state.location}
                      onChange={(e) => locationChange(e)}
                      placeholder="enter location"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Firm Name"
                      value={state.firmName}
                      onChange={(e) => firmNameChange(e)}
                      placeholder="enter firm name"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Process"
                      value={state.process}
                      onChange={(e) => processChange(e)}
                      placeholder="enter process"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Address"
                      value={state.address}
                      onChange={(e) => addressChange(e)}
                      placeholder="enter address"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Url"
                      value={state.url}
                      onChange={(e) => urlChange(e)}
                      placeholder="enter url"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="green"
              onClick={state.isEditVendor ? saveEditVendor : saveNewVendor}
            >
              {state.isEditVendor ? "Update Vendor" : "Add Vendor"}
            </Button>
          </Modal.Actions>
        </Modal>


        <Modal
          open={state.startModal}
          // onClose={closeModal}
          size="small"
        >
          <Modal.Content>
            <Form>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email To"
                      value={state.email}
                      onChange={(e) => emailChange(e)}
                      // disabled={state.isEditVendor}
                      placeholder="Enter email"
                    />
                  </Grid.Column>
                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email CC"
                      value={state.cc}
                      onChange={(e) => setState({ cc: e.target.value })}
                      placeholder="Enter email cc"
                    />
                  </Grid.Column>
                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <p style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: "sans-serif",
                      color: "#000",
                      margin: 0,
                      marginBottom: 5,
                      padding: 0
                    }}>
                      Expiry Date Time
                    </p>
                    <Stack spacing={4} sx={{ width: '100%' }} >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          // label="DD/MM/YYYY HH:mm A"
                          disablePast
                          // toolbarPlaceholder="DD/MM/YYYY HH:mm A"
                          value={state.expiryDateTime}
                          onChange={expiryDateTimeChange}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid.Column>
                  <Grid.Column width={16} style={{ marginTop: 20, }}>
                    {state.showFormsField ?
                      <>
                        <p style={{
                          fontSize: 13,
                          fontWeight: 'bold',
                          fontFamily: "sans-serif",
                          color: "#000",
                          margin: 0,
                          marginBottom: 5,
                          padding: 0
                        }}>
                          Select vendor forms
                        </p>
                        <Dropdown
                          placeholder='select forms'
                          labeled
                          fluid
                          multiple
                          selection
                          search
                          options={questionSectionsOptions}
                          onChange={handleChangeForm}
                        />
                      </>
                      :
                      <>
                        {state.showRenewal == false &&
                          <Form.Input
                            type="text"
                            label="Reject Reason"
                            value={state.rejectReason}
                            onChange={(e) => setState({ rejectReason: e.target.value })}
                            placeholder="Reject Reason"
                          />
                        }
                      </>
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="green"
              onClick={state.showFormsField ? startForm : state.showRenewal ? renewalForm : rejectForm}
            >
              {state.showFormsField ? "Initiate Form" : state.showRenewal ? "Renewal Form" : "Submit"}
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={state.startAuditModal}
          // onClose={closeModal}
          size="small"
        >
          <Modal.Content>
            <Form>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email To"
                      value={state.email}
                      onChange={(e) => emailChange(e)}
                      // disabled={state.isEditVendor}
                      placeholder="Enter email"
                    />
                  </Grid.Column>
                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email Cc"
                      value={state.cc}
                      onChange={(e) => setState({ cc: e.target.value })}
                      placeholder="Enter email cc"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <p style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: "sans-serif",
                      color: "#000",
                      margin: 0,
                      marginBottom: 5,
                      padding: 0
                    }}>
                      Expiry Date Time
                    </p>
                    <Stack spacing={4} sx={{ width: '250px' }} >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          // label="DD/MM/YYYY HH:mm A"
                          disablePast
                          // toolbarPlaceholder="DD/MM/YYYY HH:mm A"
                          value={state.expiryDateTime}
                          onChange={expiryDateTimeChange}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid.Column>
                  <Grid.Column width={16} style={{ marginTop: 20, }}>
                    <p style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: "sans-serif",
                      color: "#000",
                      margin: 0,
                      marginBottom: 5,
                      padding: 0
                    }}>
                      Select Audit forms
                    </p>
                    <Dropdown
                      placeholder='select audit forms'
                      labeled
                      fluid
                      multiple
                      selection
                      search
                      options={auditSectionsOptions}
                      onChange={handleChangeAudit}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="green"
              onClick={startAudit}
            >
              Initiate Audit
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={state.deleteModal}
          onClose={closeModal}
          size="mini"
        >
          <Modal.Header>
            Delete Vendor
          </Modal.Header>
          <Modal.Description>
            <div style={{ marginLeft: 30 }}>
              <p>   </p> Are you sure want to delete this vendor
              <p>   </p> Firm Name : {state.deleteData?.firmName ?? ""} ,<br />
              <p>   </p> Email : {state.deleteData?.email ?? ""} ,<br />
            </div>
          </Modal.Description>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="black" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="red"
              onClick={saveDeleteVendor}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={state.approveModal}
          onClose={closeModal}
          size="small"
        >
          <Modal.Header>
            Approve/Reject Vendor
          </Modal.Header>
          <Modal.Description>
            <div style={{ marginLeft: 30 }}>
              <p>   </p> Are you sure want to Approve/Reject this user
              <p>   </p> Firm Name : {state.approveData?.firmName ?? ""} ,<br />
              <p>   </p> Email : {state.approveData?.email ?? ""} ,<br />
            </div>
          </Modal.Description>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="black" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="red"
              onClick={() => saveApproveVendor(false)}
            >
              <Icon name="remove" />  Reject
            </Button>
            <Button
              color="green"
              onClick={() => saveApproveVendor(true)}
            >
              <Icon name="check circle outline" />  Approve
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={state.viewFormModal}
          onClose={closeModal}
          size="mini"
        >
          <Modal.Header>
            Vendor Status
          </Modal.Header>
          <Modal.Description>
            <div style={{ marginLeft: 30 }}>
              <p>   </p> Current Vendor Status
              <p>   </p> Firm Name : {state.viewFormData?.firmName ?? ""} ,<br />
              <p>   </p> Email : {state.viewFormData?.email ?? ""} ,<br />
              <p>   </p> Status : {state.viewFormData?.vendorStatus ?? ""} ,<br />
            </div>
          </Modal.Description>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={state.historyView}
          onClose={closeModal}
          size="small"
        >
          <Modal.Header>
            Vendor History
          </Modal.Header>
          <Modal.Content scrolling >
            <List size="medium" divided>
              {state.selectedHistory.map((item, index) => {
                return (
                  <List.Item key={index}>
                    <List.Content>
                      {item?.['status'] ?? ""} - {item?.['createdAt'] ? moment(item.createdAt).format("DD-MMM-YYYY hh:mm A") : "--"}
                      {(item?.registrationFormSentTo || item?.registrationFormMailCC) &&
                        <><br />To : {item?.registrationFormSentTo ?? "--"}, Cc : {item?.registrationFormMailCC ?? "--"}</>
                      }
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="black" onClick={closeModal}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
}


// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth,
//     user: state.user,
//     vendor: state.vendor,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       fetchVendorQuesions: fetchVendorsQuestionaction,
//       fetchVendorQuesionsSection: fetchVendorsQuestionSectionaction,
//       fetchVendors: fetchVendorsaction,
//       fetchAuditQuestions: fetchAuditQuestionaction,
//       fetchAuditQuestionsSection: fetchAuditQuestionSectionaction,
//       addVendorAction,
//       editVendorAction,
//       deleteVendorAction,
//       startVendorAction,
//       renewalVendorAction,
//       editVendorFormAction,
//       startAuditAction,
//       editAuditAction,
//       approveVendorAction
//     },
//     dispatch
//   );
// };

export default Vendors;

import {
  ADD_CALENDAR_SUCCESS_ACTION,
  ADD_CALENDAR_FAILURE_ACTION,
  EDIT_CALENDAR_SUCCESS_ACTION,
  EDIT_CALENDAR_FAILURE_ACTION,
  DELETE_CALENDAR_SUCCESS_ACTION,
  DELETE_CALENDAR_FAILURE_ACTION,
  CALENDAR_LIST_SUCCESS_ACTION,
  CALENDAR_LIST_FAILURE_ACTION,
  CALENDAR_VIEW_SUCCESS_ACTION,
  CALENDAR_VIEW_FAILURE_ACTION,
  GET_CALENDAR_SUCCESS_ACTION,
  GET_CALENDAR_FAILURE_ACTION,
} from "../actions/types";

const INIT_STATE = { calendarView: "" };

export default function calendar_reducer(state = INIT_STATE, action) {
  state = Object.assign({}, state, {});
  switch (action.type) {
    case CALENDAR_LIST_SUCCESS_ACTION:
      state.calendarList = action.payload;
      state.calendarError = false;
      return state;
    case CALENDAR_LIST_FAILURE_ACTION:
      state.calendarList = [];
      state.calendarError = true;
      return state;
    case CALENDAR_VIEW_SUCCESS_ACTION:
      state.calendarView = action.payload;
      state.calendarError = false;
      return state;
    case CALENDAR_VIEW_FAILURE_ACTION:
      state.calendarView = [];
      state.calendarError = true;
      return state;
    case ADD_CALENDAR_SUCCESS_ACTION:
      state.addCalendar = action.payload;
      state.addCalendarError = false;
      return state;
    case ADD_CALENDAR_FAILURE_ACTION:
      state.addCalendarError = true;
      return state;
    case GET_CALENDAR_SUCCESS_ACTION:
      state.getCalendar = action.payload;
      state.getCalendarError = false;
      return state;
    case GET_CALENDAR_FAILURE_ACTION:
      state.getCalendar = [];
      state.getCalendarError = true;
      return state;
    case EDIT_CALENDAR_SUCCESS_ACTION:
      state.editCalendar = action.payload;
      state.editCalendarError = false;
      return state;
    case EDIT_CALENDAR_FAILURE_ACTION:
      state.editCalendarError = true;
      return state;
    case DELETE_CALENDAR_SUCCESS_ACTION:
      state.deleteCalendar = action.payload;
      state.deleteCalendarError = false;
      return state;
    case DELETE_CALENDAR_FAILURE_ACTION:
      state.deleteCalendarError = true;
      return state;
  }
  return state;
}

import React, { useState } from "react";
import {
  Icon,
  Loader,
  Modal,
  Button,
} from "semantic-ui-react";

import {
  TableContainer,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Box,
  Collapse,
  IconButton,
  Typography,
  styled,
  tableCellClasses,

} from "@mui/material";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { viewCalendarAction, downloadCalendarFormPDFAction } from "../../actions/calendar_actions";
import { useEffect } from "react";
import FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../util";
import { logoutAction } from "../../actions";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "teal",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row(props) {
  const { row, index } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <Typography fontWeight={'bold'}>{index + 1}.</Typography> {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" colSpan={5} scope="row">
          <Typography fontWeight={'bold'} variant="h6" gutterBottom component="div">
            {row.month}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Vendor</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Attendees</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Planned Date</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Actual Date of Audit held</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.calenders && row.calenders.map((calendersItem, caledarIndex) => (
                    <TableRow key={caledarIndex}>
                      <TableCell component="th" scope="row">{calendersItem.vendor_name}</TableCell>
                      <TableCell>{calendersItem.attendees}</TableCell>
                      <TableCell align="right">{calendersItem.plannedDate}</TableCell>
                      <TableCell align="right">{calendersItem.auditedDate != "" ? calendersItem.auditedDate : '--'}</TableCell>
                      <TableCell align="right">{calendersItem.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const CalendarView = ({ storeName = "", id = "", auditsType = "", onClose = () => { }, ...props }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [calendarData, setCalendarData] = useState([])
  const [loading, setLoading] = useState(false)
  const authState = useSelector((state) => state.auth)

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
      navigate(`/login`, { replace: true });
    }
  }, [authState.isLoggedIn])

  useEffect(() => {
    setLoading(true)
    viewCalendarAction(dispatch, {
      onSuccess: (data) => {
        console.log(data, "Calendar Data")
        setCalendarData(data)
        setLoading(false)
      },
      onFailure: (data) => {
        setLoading(false)
        console.log(data, "Error Calendar Data")
      }
    })
  }, [])

  const downloadCalendarForm = async () => {
    try {
      setLoading(true)
      let apiResponse = await downloadCalendarFormPDFAction()
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, `CalendarForms.pdf`)
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Modal open={loading}>
        <Loader>Loading</Loader>
      </Modal>
      <div style={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "row", maxHeight: 40, margin: 20, marginBottom: 0 }}  >
        <h4 style={{ marginLeft: 20, color: "brown", display: "inline-block", fontSize: 25, fontFamily: "sans-serif", fontWeight: "bold", }} >
          VENDOR AUDIT CALENDER- FY({calendarData.length > 0 ? calendarData[0].year + " - " + calendarData[calendarData.length - 1].year : ""})
        </h4>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <Button
            color="google plus"
            // disabled={this.state.selection.length === 0}
            style={{ float: "right", marginRight: '10px' }}
            icon
            labelPosition="right"
            onClick={downloadCalendarForm}
          >
            <Icon name="download" />
            Calendar PDF
          </Button>
          <Button
            color="brown"
            // disabled={this.state.selection.length === 0}
            style={{ float: "right", marginRight: '10px' }}
            icon
            labelPosition="right"
            onClick={onClose}
          >
            <Icon name="arrow left" />
            Go Back
          </Button>
        </div>
      </div>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {calendarData.map((calendarItem, calendarIndex) => {
          return (
            <TableContainer key={calendarIndex} style={{ marginTop: 30 }} component={Paper}>
              <Table style={{ borderWidth: 2, borderRadius: 3, }} stickyHeader aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell ><Typography fontWeight={'bold'} variant="h6"> S.No.</Typography></StyledTableCell>
                    <StyledTableCell><Typography fontWeight={'bold'} variant="h6"> Year - {calendarItem.year} </Typography></StyledTableCell>
                    <StyledTableCell align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</StyledTableCell>
                    <StyledTableCell align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</StyledTableCell>
                    <StyledTableCell align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</StyledTableCell>
                    <StyledTableCell align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calendarItem.months && calendarItem.months.map((monthItem, monthindex) => (
                    <Row key={monthItem.month + "" + monthindex} index={monthindex} row={monthItem} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        })}

      </div>
    </div>
  );

}

export default CalendarView;

import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";

import { isLoggedIn, isAdmin } from "../util";
import { logoutAction } from "../actions/index";
import {
  Segment,
  Menu,
  Dropdown,
} from "semantic-ui-react";
import matrix from "../Images/matrix-logo.png";
import AuditConfirmationForm from "./QuestionsComponents/AuditConfirmationForm.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const AuditConfirmation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)

  const logout = () => {
    logoutAction(dispatch);
    navigate("/login");
  };

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logout()
    }
  }, [])

  const openHome = () => {
    navigate(`/home`);
  };
  const control = () => {
    navigate("/controlpanel");
  };
    return (
      <div style={{ height: "100%" }}>
        <Segment raised style={{ backgroundColor: "white", height: 60 }}>
          <div style={{ display: "inline-block" }}>
            <img
              style={{
                height: 60,
                marginTop: -13,
                float: "left",
              }}
              src={matrix}
            />
          </div>
          {(isLoggedIn(authState)) &&
            <Menu.Menu
              style={{ display: "inline", float: "right", marginTop: 8, marginRight: 50, }}
            >
              <Dropdown pointing text={authState.Name}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={openHome}>Home</Dropdown.Item>
                  {isAdmin(authState) &&
                    <Dropdown.Item onClick={control}>Control Panel</Dropdown.Item>
                  }
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>}
          <AuditConfirmationForm />
        </Segment>
      </div>
    );
  }

export default AuditConfirmation;

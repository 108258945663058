import {
  VENDORLIST_SUCCESS_ACTION,
  VENDORLIST_FAILURE_ACTION,
  VENDOR_QUESTIONS_SUCCESS_ACTION,
  VENDOR_QUESTIONS_FAILURE_ACTION,
  ADD_VENDOR_SUCCESS_ACTION,
  ADD_VENDOR_FAILURE_ACTION,
  EDIT_VENDOR_FORM_SUCCESS_ACTION,
  EDIT_VENDOR_FORM_FAILURE_ACTION,
  START_VENDOR_SUCCESS_ACTION,
  START_VENDOR_FAILURE_ACTION,
  AUDIT_QUESTIONS_SUCCESS_ACTION,
  AUDIT_QUESTIONS_FAILURE_ACTION,
  EDIT_AUDIT_FORM_SUCCESS_ACTION,
  EDIT_AUDIT_FORM_FAILURE_ACTION,
  START_AUDIT_SUCCESS_ACTION,
  START_AUDIT_FAILURE_ACTION,
  VENDOR_QUESTIONS_SECTIONS_SUCCESS_ACTION,
  AUDIT_QUESTIONS_SECTIONS_SUCCESS_ACTION,
  AUDIT_LIST_SUCCESS_ACTION,
  AUDIT_LIST_FAILURE_ACTION,
} from "../actions/types";

const INIT_STATE = {};

export default function vendor_reducer(state = INIT_STATE, action) {
  state = Object.assign({}, state, {});
  switch (action.type) {
    case VENDORLIST_SUCCESS_ACTION:
      state.allvendors = action.payload;
      state.vendorerror = false;
      return state;
    case VENDORLIST_FAILURE_ACTION:
      state.vendorerror = true;
      return state;
    case ADD_VENDOR_SUCCESS_ACTION:
      state.addvendor = action.payload;
      state.addError = false;
      return state;
    case ADD_VENDOR_FAILURE_ACTION:
      state.addError = true;
      return state;
    case START_VENDOR_SUCCESS_ACTION:
      state.startvendor = action.payload;
      state.startError = false;
      return state;
    case START_VENDOR_FAILURE_ACTION:
      state.startError = true;
      return state;
    case EDIT_VENDOR_FORM_SUCCESS_ACTION:
      state.editvendor = action.payload;
      state.editError = false;
      return state;
    case EDIT_VENDOR_FORM_FAILURE_ACTION:
      state.editError = true;
      return state;


    case VENDOR_QUESTIONS_SECTIONS_SUCCESS_ACTION:
      state.questionSections = action.payload;
      state.quesError = false;
      return state;
    case VENDOR_QUESTIONS_SUCCESS_ACTION:
      state.questions = action.payload;
      state.quesError = false;
      return state;
    case VENDOR_QUESTIONS_FAILURE_ACTION:
      state.quesError = true;
      return state;


    //Audit form
    case AUDIT_LIST_SUCCESS_ACTION:
      state.allAudits = action.payload;
      state.auditError = false;
      return state;
    case AUDIT_LIST_FAILURE_ACTION:
      state.auditError = true;
      return state;
    case START_AUDIT_SUCCESS_ACTION:
      state.startAudit = action.payload;
      state.startError = false;
      return state;
    case START_AUDIT_FAILURE_ACTION:
      state.startError = true;
      return state;
    case EDIT_AUDIT_FORM_SUCCESS_ACTION:
      state.editAudit = action.payload;
      state.editError = false;
      return state;
    case EDIT_AUDIT_FORM_FAILURE_ACTION:
      state.editError = true;
      return state;
    case AUDIT_QUESTIONS_SECTIONS_SUCCESS_ACTION:
      state.auditquestionSections = action.payload;
      state.quesError = false;
      return state;
    case AUDIT_QUESTIONS_SUCCESS_ACTION:
      state.auditquestions = action.payload;
      state.quesError = false;
      return state;
    case AUDIT_QUESTIONS_FAILURE_ACTION:
      state.quesError = true;
      return state;
  }
  return state;
}

import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import MaterialTable from "@material-table/core";
const TableView = ({
  noofRows = 5,
  columns = [],
  data = [],
  selection = false,
  loading = false,
  actions = [],
  actionsColumnIndex = 0,
  exportButton = false,
  showToolBar = false,
  onSelectionChange = () => { },
  onRowClick = () => { }
}) => {
  const [pageSize, setPageSize] = useState(noofRows);
  const [pageRanges, setPageRanges] = useState([5, 10, 20,30,40, 50, 75, 100])

  useEffect(() => {    
    setPageSize(Number(noofRows))
  }, [noofRows])

  return (
    <MaterialTable
      columns={columns}
      data={data}
      actions={selection === false ? actions : []}
      isLoading={loading !== undefined ? loading : false}
      options={{
        exportButton: exportButton,
        headerStyle: {
          backgroundColor: '#1776d2',
          color: 'white',
          fontSize: 14,
          fontWeight: "bold",
        },
        rowStyle: {
          // backgroundColor: 'lightGray',
          fontWeight: "normal",

        },
        actionsColumnIndex: actionsColumnIndex,
        showTitle: false,
        selection: selection,
        toolbar: showToolBar,
        filtering: true,
        sorting: true,
        pageSize: pageSize,
        pageSizeOptions: pageRanges,
        // filterCellStyle: { backgroundColor: "red", border: "1px solid teal" },
      }}
      onChangeRowsPerPage={(page_size) => { setPageSize(page_size) }}
      // onSelectionChange={
      //   selection === true ? onSelectionChange : null
      // }
      onRowClick={onRowClick}
    />
  );
};
export default TableView;

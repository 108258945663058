
import React, { useEffect, useState } from "react";
import {
    Icon,
    Segment,
    Button,
    Grid,
    Sidebar,
    Menu,
    Tab,
    Loader,
    Modal,
    Radio,
} from "semantic-ui-react";
import { getTemplatesAction, updateTemplatesAction } from "../../actions/users_action";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../util";
import { useNavigate } from "react-router-dom";
import { logoutAction } from "../../actions";
const TemplatesAdmin = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth)
    const [state, setStateAction] = useState({
        sideBarVisible: true,
        loading: false,
        vendorList: [],
        auditList: [],
        vendorListTemplates: {},
        auditListTemplates: {},
    })
    const setState = (data) => {
        setStateAction((dt) => {
            return { ...dt, ...data }
        })
    }
    useEffect(() => {
        if (!isLoggedIn(authState)) {
            logoutAction(dispatch)
            navigate(`/login`, { replace: true });
        }
    }, [authState.isLoggedIn])
    useEffect(() => {
        mountFunction()
    }, [])

    function mountFunction() {
        console.log("=================GETTING===============")
        setState({ loading: true })
        getTemplatesAction(dispatch,{
            onSuccess: (res) => {
                setState({
                    vendorList: JSON.parse(JSON.stringify(res['data']?.['vendor']?.['sections'] ?? [])),
                    auditList: JSON.parse(JSON.stringify(res['data']?.['audit']?.['sections'] ?? [])),
                    vendorListTemplates: JSON.parse(JSON.stringify(res['data']?.['vendor']?.['sections'] ?? [])),
                    auditListTemplates: JSON.parse(JSON.stringify(res['data']?.['audit']?.['sections'] ?? [])),
                    loading: false,
                })
            },
            onFailure: () => {
                setState({
                    vendorList: [],
                    auditList: [],
                    vendorListTemplates: [],
                    auditListTemplates: [],
                    loading: false,
                })
            }
        })
    }

    const admin = [
        {
            menuItem: { key: "audits", icon: "wpforms", content: "Audits" },
            render: () => (
                <Tab.Pane>
                    {state.auditList.length > 0 &&
                        <AuditTemplates data={state.auditList} refresh={mountFunction} />
                    }
                </Tab.Pane>
            ),
        },
        {
            menuItem: { key: "vendors", icon: "wpforms", content: "Vendors" },
            render: () => (
                <Tab.Pane>
                    {state.vendorList.length > 0 &&
                        <VendorTemplates data={state.vendorList} refresh={mountFunction} />
                    }
                </Tab.Pane>
            ),
        },

    ];

    return (
        <div style={{ height: "100%" }}>
            <Modal open={state.loading}>
                <Loader>Loading</Loader>
            </Modal>
            {authState.role === "Admin" && <Tab panes={admin} />}
        </div>
    );
}

export default TemplatesAdmin;






const AuditTemplates = ({ data = [], refresh = () => { } }) => {
    const dispatch = useDispatch()
    const [state, setStateAction] = useState({
        loading: false,
        sideBarVisible: true,
        submitConfirmationModal: false,
        questionSections: [{}],
        selectedForms: [{}],
        selectedFormIndex: 0,
        activeItem: "",
    })
    const setState = (data) => {
        setStateAction((dt) => {
            return { ...dt, ...data }
        })
    }
    useEffect(() => {
        console.log(data);
        var selectedFormsTemplate = JSON.parse(JSON.stringify(data ?? []))
        var selectedForms = data ?? []
        setState({ selectedForms, selectedFormsTemplate, selectedFormIndex: 0, activeItem: selectedFormsTemplate[0]['categoryId'] ?? "" })
    }, [])

    const cancel = () => {
        setState({
            disabled: false,
        });
    };
    const closeSubmit = () => {
        setState({
            submitConfirmationModal: false,
        });
    };
    const handleItemClick = (sectionItem, sectionIndex) => {
        console.log(sectionItem.category)
        setState({ activeItem: sectionItem.categoryId, selectedFormIndex: sectionIndex })
    }
    const back = () => {
        // this.props.history.push("/home");
    };
    const changeAnswer = (question, index, value) => {
        const { selectedFormIndex, selectedForms } = state
        var newselectedForms = [...selectedForms]
        newselectedForms[selectedFormIndex].questions[index]['mandatory'] = value
        setState({ selectedForms: newselectedForms });
    };



    const submitConfirmation = () => {
        setState({ submitConfirmationModal: true })
    }
    const submitForm = () => {
        const { selectedForms } = state
        console.log("Submit")
        setState({ loading: true })
        console.log(selectedForms)
        updateTemplatesAction(dispatch,'audit', selectedForms, {
            onSuccess: (res) => {
                setState({ loading: false, submitConfirmationModal: false })
                refresh()
                alert(res.message)
            },
            onFailure: (err) => {
                setState({ loading: false, submitConfirmationModal: false })
                alert(err.message)
            }
        })
    };
    const saveForm = () => {
        const { selectedForms } = state
        console.log("Save")
        console.log(selectedForms)
    };
    const { activeItem, selectedForms, selectedFormIndex, sideBarVisible } = state
    var pusherStyle = {
        height: "100vh",
        backgroundColor: "wheat",

        overflow: "auto",
        width: "90%",
        border: 0,
    };
    if (!sideBarVisible) {
        pusherStyle.width = "100%";
    }
    var selectedCurrentForm = selectedForms[selectedFormIndex]
    return (

        <div style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: 'white', }}>


            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    margin: '10px 0px 0px 10px',
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        flexDirection: "row",
                        margin: 0
                    }}
                >

                    <Icon
                        style={{
                            display: "inline-block",
                            cursor: "pointer",
                            float: "left",
                            color: "brown",
                        }}
                        onClick={() => { setState({ sideBarVisible: !sideBarVisible }) }}
                        size={"big"}
                        name={sideBarVisible ? "outdent" : "indent"}
                    />
                    <h4
                        style={{
                            marginLeft: 30,
                            marginTop: 0,
                            color: "brown",
                            display: "inline-block",
                            fontSize: 25,
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                        }}
                    >
                        Subcontractor Evaluation Form
                    </h4>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        margin: 0
                    }}
                >
                    <div>
                        <Button
                            color="green"
                            circular
                            onClick={submitConfirmation}
                        >
                            <Icon name="check circle" />  Update
                        </Button>
                    </div>
                    <Button
                        color="red"
                        circular
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onClick={back}
                    >
                        <Icon name="close" />  Close
                    </Button>
                </div>
            </div>

            <Sidebar.Pushable
                as={Segment}
                style={{
                    display: "flex",
                    borderRadius: 0,
                    height: "calc(100% - 70px)",
                }}
            >
                <Sidebar
                    as={Menu}
                    visible={sideBarVisible}
                    activeIndex="0"
                    style={{
                        flex: "0 0 150px",
                        backgroundColor: "#05386B",
                        paddingTop: 5,
                    }}
                    animation="slide along"
                    width="thin"
                    icon="labeled"
                    vertical
                    inverted
                >
                    <div>
                        {selectedForms.map((sectionItem, sectionIndex) => {
                            var isActive = (activeItem === sectionItem.categoryId)
                            return (
                                <Menu.Item
                                    key={sectionIndex}
                                    name={sectionItem.category}
                                    active={isActive}
                                    color="blue"
                                    onClick={() => handleItemClick(sectionItem, sectionIndex)}
                                    style={{ marginTop: 10, fontWeight: 'normal', fontSize: '13px' }}
                                >
                                    <Icon name="wpforms" color={isActive ? undefined : "teal"} />
                                    <span style={{ color: isActive ? "white" : "gray" }}>{String(sectionItem?.category ?? "").split('/').join(' / ')}</span>

                                </Menu.Item>
                            )
                        })
                        }
                    </div>
                </Sidebar>
                <Sidebar.Pusher style={pusherStyle}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <Segment raised style={{ width: "95vw", marginBottom: "5vh", marginTop: '2vh', marginRight: '1vw', marginLeft: '1vw', borderRadius: 10, padding: 20, }}>
                            <Grid >
                                <Grid.Row divided >
                                    {selectedCurrentForm?.questions != null &&
                                        selectedCurrentForm?.questions != undefined &&
                                        selectedCurrentForm?.questions.length > 0 &&
                                        selectedCurrentForm?.questions.map((ques, quesIndex) => (
                                            <React.Fragment key={quesIndex}>
                                                <Grid.Column style={{ marginTop: 10 }} width={4}>
                                                    <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                                        {ques.question}
                                                    </span>
                                                </Grid.Column>
                                                <Grid.Column style={{ marginTop: 10 }} width={4}>
                                                    <Radio toggle label='Mandatory' checked={ques?.mandatory ?? false} onChange={(e, v) => { changeAnswer(ques, quesIndex, v.checked) }} />
                                                </Grid.Column>
                                            </React.Fragment>
                                        ))}
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>

            <Modal
                open={state.submitConfirmationModal}
                onClose={closeSubmit}
                size="mini"
            >
                <Modal.Header>
                    Update Audit Template
                </Modal.Header>
                <Modal.Content>
                    Are you want to Update this Audit Form Template
                </Modal.Content>
                <Modal.Actions style={{ marginTop: 20 }}>
                    <Button color="black" onClick={closeSubmit}>
                        Cancel
                    </Button>
                    <Button color="green" onClick={submitForm}>
                        <Icon name="check circle outline" /> Update
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={state.loading}>
                <Loader>Loading</Loader>
            </Modal>

        </div>
    );
}




const VendorTemplates = ({ data = [], refresh = () => { } }) => {
    const dispatch = useDispatch()
    const [state, setStateAction] = useState({
        submitConfirmationModal: false,
        loading: false,
        sideBarVisible: true,
        questionSections: [{}],
        selectedForms: [{}],
        selectedFormIndex: 0,
        activeItem: "",
    })
    const setState = (data) => {
        setStateAction((dt) => {
            return { ...dt, ...data }
        })
    }
    useEffect(() => {
        console.log(data);
        var selectedFormsTemplate = JSON.parse(JSON.stringify(data ?? []))
        var selectedForms = data ?? []
        setState({ selectedForms, selectedFormsTemplate, selectedFormIndex: 0, activeItem: selectedFormsTemplate[0]['categoryId'] ?? "" })
    }, [])

    const cancel = () => {
        setState({
            disabled: false,
        });
    };
    const closeSubmit = () => {
        setState({
            submitConfirmationModal: false
        });
    };
    const handleItemClick = (sectionItem, sectionIndex) => {
        console.log(sectionItem.category)
        setState({ activeItem: sectionItem.categoryId, selectedFormIndex: sectionIndex })
    }
    const back = () => {

    };
    const changeAnswer = (question, index, value) => {
        const { selectedFormIndex, selectedForms } = state
        var newselectedForms = [...selectedForms]
        newselectedForms[selectedFormIndex].questions[index]['mandatory'] = value
        setState({ selectedForms: newselectedForms });
    };





    const submitConfirmation = () => {
        setState({ submitConfirmationModal: true })
    }
    const submitForm = () => {
        const { selectedForms } = state
        console.log("Submit")
        console.log(selectedForms)
        setState({ loading: true })
        updateTemplatesAction(dispatch,'vendor', selectedForms, {
            onSuccess: (res) => {
                refresh()
                setState({ loading: false, submitConfirmationModal: false })
                alert(res.message)
            },
            onFailure: (err) => {
                setState({ loading: false, submitConfirmationModal: false })
                alert(err.message)
            }
        })

    };

    const { activeItem, selectedForms, selectedFormIndex, sideBarVisible } = state
    var pusherStyle = {
        height: "100vh",
        backgroundColor: "wheat",

        overflow: "auto",
        width: "90%",
        border: 0,
    };
    if (!sideBarVisible) {
        pusherStyle.width = "100%";
    }
    var selectedCurrentForm = selectedForms[selectedFormIndex]
    return (

        <div style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: 'white' }}>
            <Modal open={state.loading}>
                <Loader>Loading</Loader>
            </Modal>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    margin: '10px 0px 0px 10px',
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        flexDirection: "row",
                        margin: 0
                    }}
                >

                    <Icon
                        style={{
                            display: "inline-block",
                            cursor: "pointer",
                            float: "left",
                            color: "brown",
                        }}
                        onClick={() => { setState({ sideBarVisible: !sideBarVisible }) }}
                        size={"big"}
                        name={sideBarVisible ? "outdent" : "indent"}
                    />
                    <h4
                        style={{
                            marginLeft: 30,
                            marginTop: 0,
                            color: "brown",
                            display: "inline-block",
                            fontSize: 25,
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                        }}
                    >
                        Vendor Registration Form
                    </h4>
                </div>
                <div
                    style={{
                        display: "flex",
                        // flex: 1,
                        alignItems: "center",
                        flexDirection: "row",
                        margin: 0
                    }}
                >
                    <div>
                        <Button
                            color="green"
                            circular
                            onClick={submitConfirmation}
                        >
                            <Icon name="check circle" />  Update
                        </Button>

                    </div>

                    <Button
                        color="red"
                        circular
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onClick={back}
                    >
                        <Icon name="close" />  Close
                    </Button>
                </div>
            </div>

            <Sidebar.Pushable
                as={Segment}
                style={{
                    // marginTop: -25,
                    display: "flex",
                    borderRadius: 0,
                    height: "calc(100% - 70px)",
                }}
            >
                <Sidebar
                    as={Menu}
                    visible={sideBarVisible}
                    activeIndex="0"
                    style={{
                        flex: "0 0 150px",
                        backgroundColor: "#05386B",
                        paddingTop: 5,
                    }}
                    animation="slide along"
                    width="thin"
                    icon="labeled"
                    vertical
                    inverted
                >
                    <div>
                        {selectedForms.map((sectionItem, sectionIndex) => {
                            var isActive = (activeItem === sectionItem.categoryId)
                            return (
                                <Menu.Item
                                    key={sectionIndex}
                                    name={sectionItem.category}
                                    active={isActive}
                                    color="blue"
                                    onClick={() => handleItemClick(sectionItem, sectionIndex)}
                                    style={{ marginTop: 10, fontWeight: 'normal', fontSize: '13px' }}
                                >
                                    <Icon name="wpforms" color={isActive ? undefined : "teal"} />
                                    <span style={{ color: isActive ? "white" : "gray" }}>{String(sectionItem?.category ?? "").split('/').join(' / ')}</span>

                                </Menu.Item>
                            )
                        })
                        }
                    </div>
                </Sidebar>
                <Sidebar.Pusher style={pusherStyle}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <Segment style={{ width: "95vw", marginBottom: "5vh", marginTop: '2vh', marginRight: '1vw', marginLeft: '1vw', borderRadius: 10, padding: 20, }}>
                            <Grid >
                                <Grid.Row >
                                    {selectedCurrentForm?.questions != null &&
                                        selectedCurrentForm?.questions != undefined &&
                                        selectedCurrentForm?.questions.length > 0 &&
                                        selectedCurrentForm?.questions.map((ques, quesIndex) => (
                                            <React.Fragment key={quesIndex}>
                                                <Grid.Column style={{ marginTop: 10 }} width={4}>
                                                    <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                                        {ques.question}
                                                    </span>
                                                </Grid.Column>
                                                <Grid.Column style={{ marginTop: 10 }} width={4}>
                                                    <Radio toggle label='Mandatory' checked={ques?.mandatory ?? false} onChange={(e, v) => { changeAnswer(ques, quesIndex, v.checked) }} />
                                                </Grid.Column>
                                            </React.Fragment>
                                        ))}
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>




            <Modal
                open={state.submitConfirmationModal}
                onClose={closeSubmit}
                size="mini"
            >
                <Modal.Header>
                    Update Vendor Template
                </Modal.Header>
                <Modal.Content>
                    Are you want to Update this Vendor Form Template
                </Modal.Content>
                <Modal.Actions style={{ marginTop: 20 }}>
                    <Button color="black" onClick={closeSubmit}>
                        Cancel
                    </Button>
                    <Button color="green" onClick={submitForm}>
                        <Icon name="check circle outline" /> Update
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={state.loading}>
                <Loader>Loading</Loader>
            </Modal>
        </div >
    );
}



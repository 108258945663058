import React, { useState, useEffect } from "react";
import {
  Icon,
  Segment,
  Button,
  Grid,
  Dropdown,
  Input,
  Sidebar,
  Menu,
  Loader,
  Modal,
} from "semantic-ui-react";
import { isLoggedIn } from "../../util";
import { saveAuditAction, submitAuditAction, getAuditCategoryAction } from "../../actions/vendor_actions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AuditCreationForm = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const isLoggedInUser = isLoggedIn(authState)
  const [state, setStateAction] = useState({
    EditView: false,
    disabled: false,
    submitModal: false,
    submitted: false,
    loading: false,
    sideBarVisible: true,
    submitConfirmationModal: false,
    questionSections: [],
    selectedForms: [],
    selectedFormIndex: 0,
    validityArray: [],
    isHasMajorCustomers: false,
    majorCustomerQuestions: [],
    imageModal: false,
    auditId: "",
    activeItem: "",
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }

  useEffect(() => {
    var auditId = params.id
    console.log(auditId, "Audit ID")
    setState({ auditId, loading: true })
    getAuditCategoryAction(dispatch, auditId, {
      onSuccess: (data) => {
        console.log("Get Category Data", data)
        var selectedFormsTemplate = JSON.parse(JSON.stringify(data.data))
        var EditView = false
        var disabled = data.isSubmitted
        var selectedForms = data.data
        if (isLoggedIn(authState)) {
          disabled = true
        }
        setState({ loading: false, submitted: data.isSubmitted, EditView, disabled, selectedForms, selectedFormsTemplate, activeItem: selectedFormsTemplate[0].categoryId, selectedFormIndex: 0 })
      },
      onFailure: (error) => {
        console.log("Get Category Failure", error)
        setState({ loading: false, disabled: true, EditView: false })
        alert(error)
      }
    })
  }, [])

  const cancel = () => {
    setState({
      EditView: false,
      disabled: false,
    });
  };
  const closeSubmit = () => {
    setState({
      submitModal: false,
      submitConfirmationModal: false,
    });
  };
  const handleItemClick = (sectionItem, sectionIndex) => {
    console.log(sectionItem.category)
    setState({ activeItem: sectionItem.categoryId, selectedFormIndex: sectionIndex })
  }
  const back = () => {
    if (isLoggedInUser) {
      navigate("/home");
    }
  };
  const changeAnswer = (question, e, isNote) => {
    const { selectedFormIndex, selectedForms } = state
    let answer = "";
    var questionsArray = selectedForms[selectedFormIndex].questions;

    if (question.answerType === "text") {
      answer = e.target.value;
      questionsArray.map((q) => {
        if (q.id == question.id) {
          if (isNote == true) {
            q.remark = answer;
          } else {
            q.answer = answer;
          }
        }
      });
    }
    if (question.answerType === "dropdown") {
      answer = e;
      questionsArray.map((q) => {
        if (q.id == question.id) {
          if (isNote == true) {
            q.remark = e.target.value;
          }
          else {
            q.answer = answer;
          }
        }
      });
    }
    var newselectedForms = selectedForms
    newselectedForms[selectedFormIndex].questions = questionsArray
    setState({
      selectedForms: newselectedForms,
    });
    checkAllAnsers()
  };


  const checkAllAnsers = () => {
    const { selectedFormIndex, selectedForms } = state
    var validityArray = []
    selectedForms.map((sitem, sindex) => {
      var isValid = true
      sitem.questions.map((qitem, qindex) => {
        if (qitem.mandatory == true) {
          if (!qitem.answer) isValid = false
          else if (qitem.answerType == "text") {
            if (qitem.answer.trim() == "") {
              isValid = false
            }
          }
        }
      })
      validityArray.push(isValid)
    })
    setState({ validityArray })
    // console.log(validityArray, "Validity Array")
  }
  const submitConfirmation = () => {
    setState({ submitConfirmationModal: true })
  }
  const submitForm = () => {
    const { selectedForms, auditId } = state
    console.log("Submit")
    console.log(selectedForms)
    setState({ loading: true })
    submitAuditAction(dispatch, auditId, selectedForms, {
      onSuccess: (data) => {
        console.log("Submit success")
        console.log(data)
        // alert("Submit success")
        setState({ submitModal: true, submitted: true, disabled: true, loading: false })
        // back()
      },
      onFailure: () => {
        console.log("Submit failed")
        setState({ loading: false })
        alert("Submit failed")
      }
    })
  };
  const saveForm = () => {
    const { selectedForms, auditId } = state
    console.log("Save")
    console.log(selectedForms)
    setState({ loading: true })
    saveAuditAction(dispatch, auditId, selectedForms, {
      onSuccess: (data) => {
        console.log(data)
        alert(data.message ?? "Save success")
        setState({ loading: false })
      },
      onFailure: () => {
        console.log("Save failed")
        setState({ loading: false })
        alert("Save failed")
      }
    })
  };

  const { activeItem, validityArray, selectedForms, selectedFormIndex, sideBarVisible, disabled, submitted } = state
  var pusherStyle = {
    height: "100vh",
    backgroundColor: "wheat",

    overflow: "auto",
    width: "90%",
    border: 0,
  };
  if (!sideBarVisible) {
    pusherStyle.width = "100%";
  }
  var selectedCurrentForm = selectedForms[selectedFormIndex]
  return (

    <div style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: 'white', }}>
      <Modal open={state.loading}>
        <Loader>Loading</Loader>
      </Modal>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          margin: '10px 0px 0px 10px',
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            flexDirection: "row",
            margin: 0
          }}
        >

          <Icon
            style={{
              display: "inline-block",
              cursor: "pointer",
              float: "left",
              color: "brown",
            }}
            onClick={() => { setState({ sideBarVisible: !sideBarVisible }) }}
            size={"big"}
            name={sideBarVisible ? "outdent" : "indent"}
          />
          <h4
            style={{
              marginLeft: 30,
              marginTop: 0,
              color: "brown",
              display: "inline-block",
              fontSize: 25,
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Subcontractor Evaluation Form
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            margin: 0
          }}
        >
          {!submitted && !disabled &&
            <div>
              <Button
                color="green"
                circular
                disabled={state.validityArray.length == 0 || state.validityArray.some((item) => item == false)}
                onClick={submitConfirmation}
              >
                <Icon name="check circle" />  Submit
              </Button>
            </div>
          }
          {!submitted && !disabled &&
            <div>
              <Button
                color="blue"
                circular
                style={{ marginLeft: 10 }}
                onClick={saveForm}
              >
                <Icon name="save outline" />  Save
              </Button>
            </div>
          }
          {isLoggedInUser ?
            <Button
              color="red"
              circular
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={back}
            >
              <Icon name="close" />  Close
            </Button>
            :
            <div style={{ marginRight: 10 }}></div>
          }

        </div>
      </div>

      <Sidebar.Pushable
        as={Segment}
        style={{
          display: "flex",
          borderRadius: 0,
          height: "calc(100% - 70px)",
        }}
      >
        <Sidebar
          as={Menu}
          visible={sideBarVisible}
          activeIndex="0"
          style={{
            flex: "0 0 150px",
            backgroundColor: "#05386B",
            paddingTop: 5,
          }}
          animation="slide along"
          width="thin"
          icon="labeled"
          vertical
          inverted
        >
          <div>
            {selectedForms.map((sectionItem, sectionIndex) => {
              var isActive = (activeItem === sectionItem.categoryId)
              var isInvalid = validityArray[sectionIndex] == false
              return (
                <Menu.Item
                  key={sectionIndex}
                  name={sectionItem.category}
                  active={isActive}
                  color="blue"
                  onClick={() => handleItemClick(sectionItem, sectionIndex)}
                  style={{ marginTop: 10, fontWeight: 'normal', fontSize: '13px' }}
                >
                  {isInvalid &&
                    <span style={{ color: "red", fontSize: '20px', position: "absolute", alignSelf: 'center', paddingLeft: 15 }}>*</span>
                  }
                  <Icon name="wpforms" color={isActive ? undefined : "teal"} />
                  <span style={{ color: isActive ? "white" : "gray" }}>{sectionItem.category.split('/').join(' / ')}</span>

                </Menu.Item>
              )
            })
            }
          </div>
        </Sidebar>
        <Sidebar.Pusher style={pusherStyle}>
          <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
            <Segment raised style={{ width: "95vw", marginBottom: "5vh", marginTop: '2vh', marginRight: '1vw', marginLeft: '1vw', borderRadius: 10, padding: 20, }}>
              <Grid >
                <Grid.Row divided >
                  {selectedCurrentForm?.questions != null &&
                    selectedCurrentForm?.questions != undefined &&
                    selectedCurrentForm?.questions.length > 0 &&
                    selectedCurrentForm?.questions.map((ques, quesIndex) => (
                      <React.Fragment key={quesIndex}>
                        {ques.answerType === "text" && (
                          <React.Fragment>
                            <Grid.Column width={4}>
                              <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                                {ques.question} {ques.mandatory == true && <span style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>*</span>}
                              </span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              {" "}
                              <Input
                                value={ques.answer ?? ""}
                                placeholder='Enter text'
                                onChange={(e) => changeAnswer(ques, e, false)}
                                disabled={disabled}
                                style={{ width: "100%" }}
                              />{" "}
                            </Grid.Column>
                          </React.Fragment>
                        )}
                        {ques.answerType === "dropdown" && (
                          <React.Fragment>
                            <Grid.Column width={4}>
                              {" "}
                              <span style={{ fontWeight: "normal", fontSize: "14px", marginTop: '15px' }}>
                                {ques.question} {ques.mandatory == true && <span style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}>*</span>}
                              </span>{" "}
                            </Grid.Column>
                            {ques.notes == true ?
                              <Grid.Column width={4}>
                                <div>
                                  <Grid.Row>
                                    <Grid.Column width={4}>
                                      <Dropdown
                                        options={ques.options.map((label, i) => {
                                          return {
                                            value: label.value,
                                            text: label.label,
                                            key: label.value,
                                          };
                                        })}
                                        onChange={(e, { value }) =>
                                          changeAnswer(ques, value, false)
                                        }
                                        style={{ width: "100%", }}
                                        value={ques.answer ?? null}
                                        selection
                                        fluid
                                        disabled={disabled}
                                        placeholder={"Select any option"}
                                      />{" "}
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                      {" "}
                                      <Input
                                        value={ques.remark ?? ""}
                                        label="Remark"
                                        placeholder='Enter text'
                                        onChange={(e) => changeAnswer(ques, e, true)}
                                        disabled={disabled}
                                        style={{ width: "100%", marginTop: 10, marginBottom: 15 }}
                                      />{" "}
                                    </Grid.Column>
                                  </Grid.Row>
                                </div>
                              </Grid.Column>
                              :
                              <Grid.Column width={4}>
                                <Dropdown
                                  options={ques.options.map((label, i) => {
                                    return {
                                      value: label.value,
                                      text: label.label,
                                      key: label.value,
                                    };
                                  })}
                                  onChange={(e, { value }) =>
                                    changeAnswer(ques, value, false)
                                  }
                                  style={{ width: "100%" }}
                                  value={ques.answer ?? null}
                                  selection
                                  fluid
                                  disabled={disabled}
                                  placeholder={"Select any option"}
                                />{" "}
                              </Grid.Column>
                            }
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>



      <Modal
        open={state.submitModal}
        onClose={closeSubmit}
        size="mini"
      >
        <Modal.Header>
          Submitted
        </Modal.Header>
        <Modal.Content>
          Form has been submitted successfully
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="green" onClick={closeSubmit}>
            <Icon name="check" /> Okay
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={state.submitConfirmationModal}
        onClose={closeSubmit}
        size="mini"
      >
        <Modal.Header>
          Submit Audit
        </Modal.Header>
        <Modal.Content>
          Are you want to Submit this Audit
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="black" onClick={closeSubmit}>
            Cancel
          </Button>
          <Button color="green" onClick={submitForm}>
            <Icon name="check circle outline" /> Submit
          </Button>
        </Modal.Actions>
      </Modal>

    </div>
  );
}

export default AuditCreationForm;

import {
  LOGIN_SUCCESS_ACTION,
  LOGIN_FAILURE_ACTION,
  LOGOUT_SUCCESS_ACTION,
  LOGOUT_FAILURE_ACTION,
  SET_EMAIL,
} from "../actions/types";
import { setupAxios } from "../util";
// import { toast } from "react-semantic-toasts";

const INIT_STATE = {
  uploadIncomplete: false,
  accessToken:"expired",
  role:"User", //Admin, 'User'
  isLoggedIn: false,
  theme: 'light',//dark , light
};
export default function auth_reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS_ACTION:
      state = {
        ...state,
        email: "",
        Name: action.payload.name,
        accessToken: action.payload.access_token,
        role: atob(action?.payload?.role ?? ""),
        expiresIn: action.payload.expires_in,
        loginError: false,
        isLoggedIn: true
      };
      // setTimeout(() => {
      //   toast({
      //     type: "info",
      //     title: action.payload.message,
      //     description: "",
      //     time: 3000,
      //   });
      // }, 0);
      setupAxios(state.accessToken);
      return state;

    case LOGIN_FAILURE_ACTION:
      console.log("Lohin Filure")
      state = {
        ...state,
        accessToken:"expired",
        loginError: true,
        isLoggedIn: false
      };
      return state;

    case LOGOUT_SUCCESS_ACTION:
      state = {
        ...state,
        accessToken:"expired",
        logoutError:false,
        isLoggedIn: false
      };
      return state;
      
      case LOGOUT_FAILURE_ACTION:
        state = {
          ...state,
          accessToken:"expired",
          logoutError:true,
          isLoggedIn: false
        }
      return state;

    case SET_EMAIL:
      state = {
        ...state,
        loginId:action.payload,
      }
      return state;
  }

  if (state.accessToken) {
    setupAxios(state.accessToken);
  }

  return state;
}

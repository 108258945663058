import authreducer from './auth_reducer';
import userreducer from './user_reducer';
import vendorreducer from './vendor_reducer';
import calendarreducer from './calendar_reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: authreducer,
    user: userreducer,
    vendor: vendorreducer,
    calendar: calendarreducer
})
export default rootReducer;
import React, { useEffect, useState } from "react";
import {
  Segment,
  Menu,
  Dropdown,
} from "semantic-ui-react";
import { logoutAction } from "../actions/index";
import { isLoggedIn, isAdmin } from "../util";
import MainView from "./Maincomponents/MainView.jsx";
import matrix from "../Images/matrix-logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const Home = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const [state, setStateAction] = useState({
    userdetail: false,
    card: "",
    visible: true,
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
      navigate('/login', { replace: true });
    }
  }, [authState.isLoggedIn])


  const logout = () => {
    let email = authState.email;
    logoutAction(dispatch, email);
  };
  const control = () => {
    navigate("/controlpanel");
  };
  const userDetail = () => {
    setState({
      userdetail: true,
    });
  };
  return (
    <div style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <Segment raised style={{ backgroundColor: "#ffffff", height: 60, marginBottom: -10 }}>
        <div style={{ display: "inline-block" }}>
          <img
            style={{
              height: 60,
              marginTop: -13,
              float: "left",
            }}
            src={matrix}
          />
        </div>

        <Menu.Menu
          style={{
            display: "inline",
            float: "right",
            marginTop: 8,
            marginRight: 50,
          }}
        >
          <Dropdown pointing text={authState.Name}>
            <Dropdown.Menu>

              {isAdmin(authState) && (
                <Dropdown.Item onClick={control}>
                  Control Panel
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Segment>
      <Segment
        basic
        style={{
          height: "100vh",
          display: "flex",
          flex: 1,
          padding: "10px 0px 0px 0px",
          backgroundColor: "white",
          overflowY: "scroll",
          marginBottom: 0
        }}
      >
        <MainView token={authState.accessToken} />
      </Segment>
    </div>
  );
}


export default Home;

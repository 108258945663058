import React, { useState, useEffect } from "react";
import { isLoggedIn } from "../../util";
import {
  Icon,
  Label,
  Modal,
  Grid,
  Form,
  Loader,
  Dropdown,
  Button,
  Popup,
  Input,
  Radio,
} from "semantic-ui-react";
import {
  fetchCalendarListAction,
  addCalendarAction,
  editCalendarAction,
  deleteCalendarAction,
  downloadCalendarListAction
} from "../../actions/calendar_actions";
import { fetchUseraction } from "../../actions/users_action";
import DatePicker from "react-datepicker";
import ReactTable from "react-table-v6";
import moment from "moment";
import FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../actions";

const Calendars = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const vendorState = useSelector((state) => state.vendor)
  const calendarState = useSelector((state) => state.calendar)
  const userState = useSelector((state) => state.user)
  const [state, setStateAction] = useState({
    createModal: false,
    isEditCalendar: false,
    deleteModal: false,
    editData: {},
    loading: false,
    calendarList: [
      // { _id: "1223", month: "may", actualDate: "04/05/2021", attendees: "attendees test", vendorId: "", vendor: "vendor test", plannedDate: "13/05/2021", status: "Completed" },
    ],
    statusList: [
      { key: "started", value: "started", text: "Started" },
      { key: "ongoing", value: "ongoing", text: "On-going" },
      { key: "completed", value: "completed", text: "Completed" },
      { key: "cancelled", value: "cancelled", text: "Cancelled" },
    ],
    vendorList: [],
    vendorListOptions: [],
    usersList: [],
    usersListOptions: [],
    // vendorListFiltered: [],
    vendor: "",
    email: "",
    // ccEmail:"",
    attendees: "",
    plannedDate: "",
    actualDate: "",
    status: "",
    dateTypeList: [
      { key: 'initiated', value: 'plannedDate', text: 'Initiated' },
      { key: 'submitted', value: 'auditedDate', text: 'Submitted' }
    ],
    dateType: "plannedDate",
    startDate: "",
    endDate: "",
  })
  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }

  useEffect(() => {
    var allVendors = vendorState?.allvendors ?? []
    if (allVendors) {
      console.log("Calendar Vendor DATA", allVendors)
      var allVendorsTemp = JSON.parse(JSON.stringify(allVendors))
      var newVenorList = allVendorsTemp.reduce((prev, item) => {
        if (item.isVendorApproved == true)
          prev.push({ key: item._id, value: item._id, text: item.firmName })
        return prev
      }, [])
      console.log("Calendar Vendor Options", newVenorList)
      setState({ vendorListOptions: newVenorList, vendorList: allVendorsTemp })
    }
    var allUsers = userState.allusers
    if (allUsers) {
      console.log("Calendar USER DATA", allUsers)
      var allUsersTemp = JSON.parse(JSON.stringify(allUsers))
      var newUsersList = allUsersTemp.map((item) => {
        return { key: item._id, value: item._id, text: item.displayName }
      })
      console.log("Calendar User Options", newUsersList)
      setState({ usersListOptions: newUsersList, usersList: allUsersTemp })
    } else {
      fetchUseraction(
        dispatch,
        {
          onSuccess: (data) => { setState({ loading: false }) },
          onFailure: () => { setState({ loading: false }) }
        });
    }
    getCalendarList()
  }, [])

  const getCalendarList = () => {
    setState({ loading: true })
    fetchCalendarListAction(
      dispatch,
      {
        onSuccess: (data) => { setState({ loading: false }) },
        onFailure: () => { setState({ loading: false }) }
      });
  }
  useEffect(() => {
    var data = calendarState.calendarList
    var allVendors = vendorState.allvendors
    //Calendar Data
    if (data) {
      console.log("Calendar DATA", data)
      setState({ calendarList: data })
    }
    //Vendor Data
    if (allVendors) {
      console.log("Calendar Vendor DATA", allVendors)
      var allVendorsTemp = JSON.parse(JSON.stringify(allVendors))
      var newVenorList = allVendorsTemp.map((item) => {
        return { key: item._id, value: item._id, text: item.firmName }
      })
      console.log("Calendar Vendor Options", newVenorList)
      setState({ vendorListOptions: newVenorList, vendorList: allVendorsTemp })
    }
    var allUsers = useState.allusers
    if (allUsers) {
      console.log("Calendar USER DATA", allUsers)
      var allUsersTemp = JSON.parse(JSON.stringify(allUsers))
      var newUsersList = allUsersTemp.map((item) => {
        return { key: item._id, value: item._id, text: item.displayName }
      })
      console.log("Calendear User Options", newUsersList)
      setState({ usersListOptions: newUsersList, usersList: allUsersTemp })
    }
  }, [calendarState.calendarList, vendorState.allvendors, useState.allusers])


  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
      navigate(`/login`, { replace: true });
    }
  }, [authState.isLoggedIn])

  const emailChange = (e) => {
    setState({
      email: e.target.value,
    });
  };

  const plannedDateChange = (e) => {
    setState({
      plannedDate: moment(e).format("DD/MM/YYYY"),
    });
  };
  const actualDateChange = (e) => {
    setState({
      actualDate: moment(e).format("DD/MM/YYYY"),
    });
  };
  const statusChange = (e) => {
    setState({
      status: e.target.value,
    });
  };
  const createNewCalendar = () => {
    setState({
      createModal: true,
      isEditCalendar: false,
      vendor: "",
      attendees: "",
      plannedDate: "",
      actualDate: "",
      email: "",
      status: "",
    });
  };
  const editCalendar = (data) => {
    setState({
      createModal: true,
      isEditCalendar: true,
      editData: data,
      vendor: { name: data.vendorName, id: data.vendorId },
      attendees: { name: data.assigneeName, id: data.assigneeId },
      plannedDate: moment(new Date(data.plannedDate)).format("DD/MM/YYYY"),
      actualDate: data.actualDate,
      email: data.email,
      status: data.status,
    });
  };
  const deleteCalendarConfirmation = (data) => {
    setState({
      deleteModal: true,
      editData: data
    });
  }
  const closeModal = () => {
    setState({
      createModal: false,
      isEditCalendar: false,
      deleteModal: false,
    });
  };

  const saveNewCalendar = () => {
    const { vendor, attendees, plannedDate, email } = state
    var newCalendarData = {
      vendor: vendor.name,
      vendorId: vendor.id,
      asigneeId: attendees.id,
      plannedDate,
      email
    }
    console.log(newCalendarData, "New Calendar Data")
    // return
    setState({ loading: true })
    addCalendarAction(
      dispatch,
      newCalendarData,
      {
        onSuccess: (data) => {
          setState({ loading: false, createModal: false })
          alert(data.message)
          if (data.success == true) {
            getCalendarList()
          }
        },
        onFailure: (data) => {
          setState({ loading: false })
          alert(data)
          // setState({createModal: false})
        }
      });

  };

  const saveEditCalendar = () => {
    const { vendor, attendees, plannedDate, actualDate, status, editData } = state
    var editCalendarData = {
      ...editData,
      calenderId: editData._id,
      assigneeId: attendees.id,
      assigneeName: attendees.name,
      plannedDate,
      actualDate,
      status,
    }
    // var editVendorData = Object.assign(editData, editCalendarData)
    console.log(editCalendarData, "New Calendar Data")
    setState({ loading: true })
    editCalendarAction(
      dispatch,
      editCalendarData,
      {
        onSuccess: (data) => {
          setState({ loading: false, createModal: false, isEditCalendar: false, })
          alert(data.message)
          getCalendarList()
        },
        onFailure: (data) => {
          setState({ loading: false, })
          alert(data)
          // setState({ createModal: false, isEditCalendar: false }) 
        }
      });

  };

  const saveDeleteCalendar = () => {
    const { editData } = state
    deleteCalendarAction(
      dispatch,
      editData._id,
      {
        onSuccess: (data) => {
          setState({ loading: false, deleteModal: false, editData: {}, isEditCalendar: false, })
          alert(data.message)
          getCalendarList()
        },
        onFailure: (data) => {
          setState({ loading: false })
          alert(data)
        }
      });
  }


  const handleChangeStatus = (e, data) => {
    console.log(data, "STATUS")
    setState({ status: data.value })
  }
  const handleChangeVendor = (e, data) => {
    console.log(data, "Vendor")
    const { vendorList } = state
    console.log("vendorList", vendorList)
    var selected = vendorList.filter((item) => item._id == data.value)
    console.log("Selected", selected)
    setState({ vendor: { name: selected[0].firmName, id: selected[0]._id }, email: selected[0].email })
  }
  const handleChangeAttendees = (e, data) => {
    console.log(data, "Users")
    const { usersListOptions } = state
    console.log("usersList", usersListOptions)
    var selected = usersListOptions.filter((item) => item.key == data.value)
    console.log("Selected", selected)
    setState({ attendees: { name: selected[0].text, id: selected[0].key } })
  }
  function getRequestData() {
    let requestData = {
      dateType: state.dateType ?? "",
      filterType: 'all',
      startDate: state.startDate ?? "",
      endDate: state.endDate ?? ""
    }
    if (requestData.startDate != "" || requestData.endDate != "") {
      requestData['filterType'] = 'range'
    }
    return requestData
  }
  const downloadCalendarList = async () => {
    try {
      setState({ loading: true })
      console.log("File available")
      let requestData = await getRequestData()
      let apiResponse = await downloadCalendarListAction(requestData)
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, 'CalendarList.xlsx')
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }
  const changeDates = (e, { value }) => setState({ [`${e?.target?.name ?? 'name'}`]: value });

  const { calendarList, usersListOptions, vendorListOptions, isEditCalendar } = state
  var columns = [
    // {
    //   Header: "S.No",
    //   accessor: "_id",
    // },
    // {
    //   Header: "Month",
    //   accessor: "month",
    // },
    {
      Header: "Vendor",
      accessor: "vendorName",
    },
    {
      Header: "Attendees",
      accessor: "assigneeName",
    },
    {
      Header: "Planned Date",
      accessor: "plannedDate",
      Cell: (row) => (
        <div>
          {row.original.plannedDate.slice(0, 10)}
        </div>
      )
    },
    {
      Header: "Actual Date",
      accessor: "auditedDate",
      Cell: (row) => (
        <div>
          {row?.original?.auditedDate?.slice(0, 10) ?? ''}
        </div>
      )
    },
    {
      Header: "Status",
      accessor: "calenderStatus",
    },
    {
      Header: "Actions",
      accessor: "actions",
      minWidth: 60,
      Cell: (row) => (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          <Popup
            content="Edit Calendar Details"
            trigger={
              <Icon
                name="pencil alternate"
                size="large"
                onClick={() => editCalendar(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "#21BA45",
                }}
              />
            }
          />
          <Popup
            content="Delete Calendar"
            trigger={
              <Icon
                name="trash alternate outline"
                size="large"
                onClick={() => deleteCalendarConfirmation(row.original)}
                style={{
                  width: 30,
                  align: "center",
                  cursor: "pointer",
                  color: "#DB2828",
                }}
              />
            }
          />
        </div>
      )
    },
    // {
    //   Header: "Edit",
    //   accessor: "editing",
    //   Cell: (row) => (
    //     <div>
    //       {!state.isEditCalendar && (
    //         <Icon
    //           name="edit"
    //           size="large"
    //           onClick={() => editCalendar(row.original)}
    //           style={{
    //             width: 100,
    //             align: "center",
    //             size: "huge",
    //             cursor: "pointer",
    //             color: "green",
    //           }}
    //         />
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Delete",
    //   accessor: "delete",
    //   Cell: (row) => (
    //     <div>
    //       <Icon
    //         name="trash"
    //         size="large"
    //         onClick={() => deleteCalendarConfirmation(row.original)}
    //         style={{
    //           width: 100,
    //           align: "center",
    //           size: "huge",
    //           marginLeft: "25%",
    //           cursor: "pointer",
    //           color: "red",
    //         }}
    //       ></Icon>
    //     </div>
    //   ),
    // }
  ];

  return (
    <div style={{ height: "100%", backgroundColor: "white" }}>

      <Modal open={state.loading}>
        <Loader>Loading</Loader>
      </Modal>
      <div style={{ width: "95vw" }}>
        <h1
          style={{
            paddingLeft: 30,
            flex: "0 0 30px",
            width: '30vw',
            display: "inline-block",
            color: "teal",
          }}
        >
          Calendar Details
        </h1>
        {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}> */}
        {state.dateTypeList.map((item) => {
          return (
            <Radio
              key={item.key}
              label={item.text}
              value={item.value}
              onChange={(e, d) => { setState({ 'dateType': d.value }) }}
              style={{ marginRight: '10px' }}
              checked={state.dateType == item.value}
            />
          )
        })}
        <Input
          type="date"
          size="small"
          name='startDate'

          // value={state.startDate}
          label="Start Date"
          onChange={changeDates}
        />
        <Input
          type="date"
          size="small"
          name='endDate'
          label='End Date'
          style={{ marginLeft: '10px' }}
          // value={state.endDate}
          onChange={changeDates}
        />
        <Label
          style={{ marginLeft: 10, marginBottom: 10, cursor: "pointer", float: 'right', marginTop: 8 }}
          size="large"
          color="blue"
          onClick={createNewCalendar}
        >
          <Icon name="calendar alternate outline" />
          Add Calendar
        </Label>

        <Label
          style={{ marginLeft: 10, marginBottom: 10, cursor: "pointer", float: 'right', marginTop: 8 }}
          size="large"
          color="blue"
          onClick={() => { downloadCalendarList() }}
        >
          <Icon name="download" />
          Calendar List
        </Label>

        {/* </div> */}
        <div style={{ display: "flex", overflowX: "scroll" }}>
          <ReactTable
            noDataText="We couldn't find anything"
            filterable={true}
            defaultPageSize={10}
            sortable={true}
            style={{ height: "72vh", width: "94vw", marginLeft: 30 }}
            columns={columns}
            data={calendarList}
          />
        </div>

        <Modal
          open={state.createModal}
          // onClose={closeModal}
          size="small"
        >
          <Modal.Content>
            <Form>
              <Grid columns={2}>
                <Grid.Row>


                  <Grid.Column style={{ marginTop: 20, }}>
                    <p style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: "sans-serif",
                      color: "#000",
                      margin: 0,
                      marginBottom: 5,
                      padding: 0
                    }}>
                      Select Vendor
                    </p>
                    <Dropdown
                      placeholder='select vendor'
                      labeled
                      fluid
                      selection
                      disabled={isEditCalendar}
                      search
                      options={vendorListOptions}
                      value={state.vendor?.id != "" ? state.vendor.id : undefined}
                      onChange={handleChangeVendor}
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20, }}>
                    <p style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: "sans-serif",
                      color: "#000",
                      margin: 0,
                      marginBottom: 5,
                      padding: 0
                    }}>
                      Select attendees
                    </p>
                    <Dropdown
                      placeholder='select attendees'
                      labeled
                      fluid
                      selection
                      search
                      options={usersListOptions}
                      value={state.attendees?.id != "" ? state.attendees.id : undefined}
                      onChange={handleChangeAttendees}
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email"
                      value={state.email}
                      onChange={(e) => emailChange(e)}
                      placeholder="enter email"
                    />
                  </Grid.Column>

                  {/* <Grid.Column style={{ marginTop: 20 }}>
                      <Form.Input
                        type="text"
                        label="CC Email"
                        value={state.ccEmail}
                        onChange={(e) => ccEmailChange(e)}
                        placeholder="enter cc email"
                      />
                    </Grid.Column> */}

                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <p style={{
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: "sans-serif",
                      color: "#000",
                      margin: 0,
                      marginBottom: 5,
                      padding: 0
                    }}>
                      Planned Date
                    </p>
                    <DatePicker
                      selected={state.plannedDate != "" ? new Date(moment(state.plannedDate, 'DD/MM/YYYY')) : null}
                      onChange={(date) => plannedDateChange(date)}
                      className="form-control"
                      monthsShown={1}
                      dateFormat={"dd/MM/yyyy"}
                      minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                      placeholderText="DD/MM/YYYY"
                      popperPlacement="bottom"
                      style={{
                        display: "inline-block",
                        width: "100%"
                      }}
                      // disabled={!state.disabled}
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"],
                        },
                        preventOverflow: {
                          enabled: false,
                        },
                        hide: {
                          enabled: false,
                        },
                      }}
                    />
                  </Grid.Column>

                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="green"
              onClick={state.isEditCalendar ? saveEditCalendar : saveNewCalendar}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>




        <Modal
          open={state.deleteModal}
          onClose={closeModal}
          size="mini"
        >
          <Modal.Content>
            Are you sure want to delete Calendar
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 20 }}>
            <Button color="black" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="red"
              onClick={saveDeleteCalendar}
            >
              <Icon name="remove" /> Delete
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
}

export default Calendars;

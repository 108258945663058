import Vendors from "../components/ControlPanelComponents/Vendors.jsx";
import Users from "../components/ControlPanelComponents/Users.jsx";
import Calendars from "./ControlPanelComponents/Calendars.jsx";
import Templates from "./ControlPanelComponents/Templates.jsx";


import React, { useEffect } from "react";
import {
  Segment,
  Menu,
  Dropdown,
  Tab,
} from "semantic-ui-react";
import matrix from "../Images/matrix-logo.png";
import { logoutAction } from "../actions/index";
import { isLoggedIn, isAdmin } from "./../util";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const ControlPanel = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)

  const logout = () => {
    logoutAction(dispatch)
    navigate("/login");
  };

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
    }
    if (!isAdmin(authState)) {
      navigate(`/`, { replace: true });
    }
  }, [authState.isLoggedIn])


  const openHome = () => {
    navigate(`/home`);
  };
  const admin = [
    {
      menuItem: { key: "vendors", icon: "address card outline", content: "Vendors" },
      render: () => (
        <Tab.Pane>
          <Vendors />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "users", icon: "users", content: "Users" },
      render: () => (
        <Tab.Pane>
          <Users />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "calendars", icon: "calendar alternate outline", content: "Calendars" },
      render: () => (
        <Tab.Pane>
          <Calendars />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "templates", icon: "wpforms", content: "Templates" },
      render: () => (
        <Tab.Pane>
          <Templates />
        </Tab.Pane>
      ),
    }

  ];

  return (
    <div style={{ height: "100%" }}>
      <Segment raised style={{ backgroundColor: "white", height: 60, }}>
        <div style={{ display: "inline-block" }}>
          <img
            style={{
              height: 60,
              marginTop: -13,
              float: "left",
            }}
            src={matrix}
          />
        </div>
        <Menu.Menu
          style={{ display: "inline", float: "right", marginTop: 8, marginRight: 50, }}
        >
          <Dropdown pointing text={authState.Name}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={openHome}>Home</Dropdown.Item>
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Segment>
      {authState.role === "Admin" && <Tab panes={admin} />}
    </div>
  );
}

export default ControlPanel;

import axios from "axios";
import * as types from "./types";

const isUnAuthorized = function (res = {}, dispatch = () => { }) {
    if (res?.['response']?.['status'] == 401) {
        dispatch({
            type: types.LOGOUT_SUCCESS_ACTION
        });

    }
}

export const fetchUseraction = (dispatch, { onSuccess, onFailure }) => {
    axios.get(types.API_URL + `vam/v1/getUsers`)
        .then(function (response) {
            dispatch({
                type: types.USERLIST_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess(response.data)
        })
        .catch(function (error) {
            dispatch({
                type: types.USERLIST_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure()
        });
};

export const adduserAction = (dispatch, addUserData, { onSuccess, onFailure }) => {
    console.log("Add User Data", addUserData)
    axios.put(types.API_URL + `vam/v1/addUser`, addUserData)
        .then(function (response) {
            alert(response.data.message)
            if (response.data.success == true) {
                dispatch({
                    type: types.ADD_USER_SUCCESS_ACTION,
                    payload: response.data
                });
            }
            onSuccess(response);
        })
        .catch(function (error) {
            dispatch({
                type: types.ADD_USER_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure(error)
        });
};

export const EditUserAction = (dispatch, editData, { onSuccess, onFailure }) => {
    axios.put(types.API_URL + `vam/v1/editUser`, editData)
        .then(function (response) {
            // alert(response.data.message)
            dispatch({
                type: types.EDIT_USER_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess(response.data);
        })
        .catch(function (error) {
            dispatch({
                type: types.EDIT_USER_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure(error);
        });
};

export const DeleteUserAction = (dispatch, user_id, { onSuccess, onFailure }) => {
    axios.post(types.API_URL + `vam/v1/deleteUsers?id=${user_id}`,)
        .then(function (response) {
            alert(response.data.message)
            dispatch({
                type: types.DELETE_USER_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess();
        })
        .catch(function (error) {
            dispatch({
                type: types.DELETE_USER_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure();
        });
};


export const changePasswordAction = (dispatch, changePasswordData, { onSuccess, onFailure }) => {
    axios.put(types.API_URL + 'vam/v1/changePassword', changePasswordData)
        .then(function (response) {
            alert(response.data.message)
            dispatch({
                type: types.CHANGE_PASSWORD_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess(response.data)
        })
        .catch(function (error) {
            dispatch({
                type: types.CHANGE_PASSWORD_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure()
        });
};



export const getTemplatesAction = (dispatch,{ onSuccess, onFailure }) => {
    axios.get(types.API_URL + `vam/v1/getTemplates`)
        .then(function (response) {
            if (response.data.success == true) {
                onSuccess(response.data)
            }
            else {
                onFailure(response.data)
            }
        })
        .catch(function (error) {
            isUnAuthorized(error,dispatch)
            onFailure({ message: error.message })
        });
}

export const updateTemplatesAction = (dispatch,templateType, sections, { onSuccess, onFailure }) => {
    axios.post(types.API_URL + `vam/v1/updateTemplate`, { templateType, sections })
        .then(function (response) {
            if (response.data.success == true) {
                onSuccess(response.data)
            }
            else {
                onFailure(response.data)
            }
        })
        .catch(function (error) {
            isUnAuthorized(error,dispatch)
            onFailure({ message: error.message })
        });
}


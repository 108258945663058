import React, { useEffect, useState } from "react";
import {
    Icon,
    Segment,
    Button,
    Grid,
    Dropdown,
    Input,
    Form,
    Loader,
    Modal,
} from "semantic-ui-react";
import { isAdmin } from "../../util";
import { fetchAuditQuestionSectionaction } from "../../actions/vendor_actions";
import { getCalendarAction, approveCalendarAction } from "../../actions/calendar_actions";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AuditConfirmationForm = () => {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth)
    const vendorState = useSelector((state) => state.vendor)
    const [state, setStateAction] = useState({
        disabled: false,
        submitModal: false,
        approveModal: false,
        rejectModal: false,
        submitted: false,
        loading: false,
        auditSectionsOptions: [],
        auditForms: [],
        email: "",
        ccEmail: "",
        status: "",
        calendarId: "",
        expiryDateTime: "",
        vendorDetails: {
            _id: "-",
            address: "-",
            email: "",
            firmName: "-",
            location: "-",
            process: "-",
            registrationFromSentTo: "",
            url: "",
            vendorStatus: ""
        },
        calenderDetails: {
            _id: "",
            assigneeName: "",
            calenderStatus: "",
            email: "",
            plannedDate: "",
            vendorName: ""
        },
    })
    const setState = (data) => {
        setStateAction((dt) => {
            return { ...dt, ...data }
        })
    }

    useEffect(() => {
        var calendarId = params.id
        console.log(calendarId, "Calendar id")
        setState({ calendarId })
        getCalendarDetails(calendarId)
        getAuditQuestionSections()
    }, [])

    useEffect(() => {
        var auditquestionSectionData = vendorState.auditquestionSections
        //Audit Data
        if (auditquestionSectionData) {
            console.log("Audit DATA", auditquestionSectionData)
            setState({ auditSectionsOptions: JSON.parse(JSON.stringify(auditquestionSectionData)) })
        }
    }, [vendorState.auditquestionSections])

    const getCalendarDetails = (calendarId) => {
        setState({ loading: true })
        getCalendarAction(
            dispatch,
            calendarId,
            {
                onSuccess: (response) => {
                    console.log(response, "GET CALENDAR")
                    var data = response.data
                    if (response.success == true) {
                        var vendorDetails = {
                            _id: data.vendorDetails["_id"],
                            address: data.vendorDetails["address"],
                            email: data.vendorDetails["email"],
                            firmName: data.vendorDetails["firmName"],
                            location: data.vendorDetails["location"],
                            process: data.vendorDetails["process"],
                            url: data.vendorDetails["url"],
                            // registrationFromSentTo: data.vendorDetails["registrationFromSentTo"],
                            // vendorStatus: data.vendorDetails["vendorStatus"]
                        }
                        var calenderDetails = {
                            _id: data.calenderDetails["_id"],
                            assigneeName: data.calenderDetails["assigneeName"],
                            calenderStatus: data.calenderDetails["calenderStatus"],
                            email: data.calenderDetails["email"],
                            plannedDate: data.calenderDetails["plannedDate"],
                            vendorName: data.calenderDetails["vendorName"]
                        }
                        // console.log("vendorDetails", vendorDetails)
                        // console.log("calenderDetails", calenderDetails)
                        if (calenderDetails.calenderStatus == 'Scheduled' || calenderDetails.calenderStatus == 'Re-scheduled') {
                            setState({ submitted: false })
                        }
                        else {
                            setState({ submitted: true })
                        }
                        setState({
                            vendorDetails: vendorDetails,
                            calenderDetails: calenderDetails,
                        })
                    }
                    setState({ loading: false })
                },
                onFailure: (data) => {
                    console.log(data, "GET CALENDAR FAILURE")
                    setState({
                        loading: false,
                        submitted: true
                    })
                    alert(data)
                }
            });
    }
    const getAuditQuestionSections = () => {
        setState({ loading: true })
        fetchAuditQuestionSectionaction(
            dispatch,
            {
                onSuccess: (data) => {
                    setState({ loading: false })
                },
                onFailure: () => {
                    setState({ loading: false })
                }
            });
    }
    const cancel = () => {
        setState({
            EditView: false,
            disabled: false,
        });
    };
    const closeModal = () => {
        setState({
            submitModal: false,
            rejectModal: false,
            approveModal: false,
            auditForms: [],
            email: "",
            expiryDateTime: ""
        });
    };
    const back = () => {
        if (isAdmin(authState)) {
            navigate("/controlpanel");
        } 
        else {
            navigate("/home");
        }
    };
    const emailChange = (e) => {
        setState({
            email: e.target.value,
        });
    };
    const ccEmailChange = (e) => {
        setState({
            ccEmail: e.target.value,
        });
    };
    const expiryDateTimeChange = (newValue) => {
        console.log(newValue)
        if (newValue) {
            if (newValue._isValid == true) {
                console.log(newValue._d, "Date")
                setState({ expiryDateTime: newValue._d })
            }
        }
    }
    const approveAudit = () => {
        const { vendorDetails } = state
        setState({
            approveModal: true,
            email: vendorDetails.email
        })
    }
    const rejectAudit = () => {
        setState({
            rejectModal: true
        })
    }

    const saveRejectAudit = () => {
        const { calendarId } = state;
        var rejectDetails = {
            id: calendarId,
            isApproved: false,
        }
        console.log(rejectDetails, "Approve")
        // return

        setState({ loading: true })
        approveCalendarAction(dispatch, rejectDetails, {
            onSuccess: (data) => {
                console.log("Reject Success Calendar", data)
                setState({ rejectModal: false, loading: false, submitted: true, expiryDateTime: "" })
                getCalendarDetails(calendarId)
                alert(data.message)
            },
            onFailure: (data) => {
                console.log("Reject Failure Calendar", data)
                setState({ loading: false })
                alert(data)
            }
        })
    }

    const saveApproveAudit = () => {
        const { calendarId, auditForms, email, ccEmail, expiryDateTime } = state;
        var approveDetails = {
            id: calendarId,
            email,
            mailCC: ccEmail,
            expiryDateTime: moment(expiryDateTime).format("DD/MM/YYYY HH:mm:ss"),
            isApproved: true,
            initiatedSections: auditForms
        }
        console.log(approveDetails, "Approve")
        // return

        if (approveDetails.initiatedSections.length == 0) {
            alert("Please Select Audit Forms")
            return
        }
        // return
        setState({ loading: true })
        approveCalendarAction(dispatch, approveDetails, {
            onSuccess: (data) => {
                console.log("Succes Approve Calendar", data)
                setState({ approveModal: false, loading: false, submitted: true, expiryDateTime: "" })
                getCalendarDetails(calendarId)
                alert(data.message)
            },
            onFailure: (data) => {
                console.log("Failure Approve Calendar", data)
                setState({ loading: false })
                alert(data)
            }
        })

    }

    const handleChangeAudit = (e, data) => {
        console.log(data.value)
        setState({ auditForms: data.value })

    }
    const { submitted, vendorDetails, calenderDetails, auditSectionsOptions } = state

    return (

        <div style={{ flex: 1, display: "flex", height: '100%', flexDirection: "column" }}>
            <Modal open={state.loading}>
                <Loader>Loading</Loader>
            </Modal>

            <div style={{ display: "flex", marginTop: 10, flex: 1, justifyContent: "center" }}>
                <Segment style={{ width: "99vw", marginBottom: "5%" }}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "space-between", alignItems: "center", flexDirection: "row", }} >
                        <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "row", margin: 0 }}>
                            <h4 style={{ marginLeft: 10, marginTop: 10, color: "brown", display: "inline-block", fontSize: 25, fontFamily: "sans-serif", fontWeight: "bold", }}>
                                Scheduled Audit Confirmation Form
                            </h4>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", margin: 0 }}>
                            <Button
                                color="brown"
                                style={{ marginLeft: 10, marginRight: 10 }}
                                onClick={back}
                            >
                                <Icon name="close" />  Close
                            </Button>
                        </div>
                    </div>
                    <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "row", margin: 0 }}>
                        <h4 style={{ marginLeft: 10, marginTop: 10, color: "green", display: "inline-block", fontSize: 25, fontFamily: "sans-serif", fontWeight: "bold", }}>
                            Calendar Details
                        </h4>
                    </div>
                    <div style={{ margin: "40px 20px 20px 20px" }}>
                        <Grid>
                            <Grid.Row>
                                {Object.keys(calenderDetails).map((item) => {
                                    if (item != "_id") {
                                        return (
                                            <>
                                                <Grid.Column width={2} style={{ marginTop: 10 }} >
                                                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>{item.charAt(0).toUpperCase() + item.slice(1).replace(/\B([A-Z])\B/g, ' $1')}</span>
                                                </Grid.Column>
                                                <Grid.Column width={6} style={{ marginTop: 10 }} >
                                                    {" "}
                                                    <Input
                                                        value={Object(calenderDetails)[item] ?? ""}
                                                        placeholder='Enter text'
                                                        disabled={true}
                                                        style={{ width: "100%" }}
                                                    />{" "}
                                                </Grid.Column>
                                            </>
                                        )
                                    }
                                })
                                }
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "row", margin: 0 }}>
                        <h4 style={{ marginLeft: 10, marginTop: 10, color: "green", display: "inline-block", fontSize: 25, fontFamily: "sans-serif", fontWeight: "bold", }}>
                            Vendor Details
                        </h4>
                    </div>
                    <div style={{ margin: "40px 20px 20px 20px" }}>
                        <Grid>
                            <Grid.Row>
                                {Object.keys(vendorDetails).map((item) => {
                                    if (item != "_id") {
                                        return (
                                            <>
                                                <Grid.Column width={2} style={{ marginTop: 10 }}>
                                                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>{item.charAt(0).toUpperCase() + item.slice(1).replace(/\B([A-Z])\B/g, ' $1')}</span>
                                                </Grid.Column>
                                                <Grid.Column width={6} style={{ marginTop: 10 }}>
                                                    {" "}
                                                    <Input
                                                        value={Object(vendorDetails)[item] ?? ""}
                                                        placeholder='Enter text'
                                                        disabled={true}
                                                        style={{ width: "100%" }}
                                                    />{" "}
                                                </Grid.Column>
                                            </>
                                        )
                                    }
                                })
                                }
                            </Grid.Row>

                            {!submitted &&
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button
                                            color="red"
                                            style={{ marginLeft: 10, marginRight: 10, width: 200, fontSize: '16px', height: 45 }}
                                            onClick={rejectAudit}
                                        >
                                            <Icon name="close" size={'large'} /> Reject
                                        </Button>
                                        <Button
                                            color="green"
                                            style={{ marginLeft: 10, marginRight: 10, width: 200, fontSize: '16px', height: 45 }}
                                            onClick={approveAudit}
                                        >
                                            <Icon name="check" size="large" />  Approve
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                    </div>
                </Segment>
            </div>

            <Modal
                open={state.rejectModal}
                onClose={closeModal}
                size="mini"
            >
                <Modal.Header>
                    Reject Audit
                </Modal.Header>
                <Modal.Content>
                    Are you want to Reject this Audit
                </Modal.Content>
                <Modal.Actions style={{ marginTop: 20 }}>
                    <Button color="black" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button color="red" onClick={saveRejectAudit}>
                        <Icon name="close" /> Reject
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                open={state.approveModal}
                size="small"
            >
                <Modal.Content>
                    <Form>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={8} style={{ marginTop: 20 }}>
                                    <Form.Input
                                        type="text"
                                        label="Email"
                                        value={state.email}
                                        onChange={(e) => emailChange(e)}
                                        placeholder="enter email"
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ marginTop: 20 }}>
                                    <Form.Input
                                        type="text"
                                        label="CC Email"
                                        value={state.ccEmail}
                                        onChange={(e) => ccEmailChange(e)}
                                        placeholder="enter cc email"
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ marginTop: 20 }}>
                                    <p style={{
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        fontFamily: "sans-serif",
                                        color: "#000",
                                        margin: 0,
                                        marginBottom: 5,
                                        padding: 0
                                    }}>
                                        Expiry Date Time
                                    </p>
                                    <Stack spacing={4} sx={{ width: '100%' }} >
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <MobileDateTimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                disablePast
                                                value={state.expiryDateTime}
                                                onChange={expiryDateTimeChange}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                </Grid.Column>
                                <Grid.Column width={16} style={{ marginTop: 20, }}>
                                    <p style={{
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        fontFamily: "sans-serif",
                                        color: "#000",
                                        margin: 0,
                                        marginBottom: 5,
                                        padding: 0
                                    }}>
                                        Select Audit forms
                                    </p>
                                    <Dropdown
                                        placeholder='select audit forms'
                                        labeled
                                        fluid
                                        multiple
                                        selection
                                        search
                                        options={auditSectionsOptions}
                                        onChange={handleChangeAudit}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions style={{ marginTop: 20 }}>
                    <Button color="red" onClick={closeModal}>
                        <Icon name="remove" /> Cancel
                    </Button>
                    <Button
                        color="green"
                        onClick={saveApproveAudit}
                    >
                        Initiate Audit
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                open={state.submitModal}
                onClose={closeModal}
                size="mini"
            >
                <Modal.Header>
                    Submitted
                </Modal.Header>
                <Modal.Content>
                    Form has been submitted successfully
                </Modal.Content>
                <Modal.Actions style={{ marginTop: 20 }}>
                    <Button color="green" onClick={closeModal}>
                        <Icon name="check" /> Okay
                    </Button>
                </Modal.Actions>
            </Modal>

        </div>
    );
}

export default AuditConfirmationForm;

import React, { useState, useEffect } from "react";
import {
  Icon,
  Button,
  Modal,
  Loader,
  Input,
  List,
  Grid,
  Form,
  Radio
} from "semantic-ui-react";
import CalendarView from "../Maincomponents/CalendarView.jsx";
import { fetchAuditsaction, approveAuditAction, renewalAuditAction, rejectAuditAction, downloadAuditsList, downloadConsolidatedAuditReport, downloadAuditFormPDF } from "../../actions/vendor_actions.js";
import TableView from "../utils/TableView.jsx";
import FileSaver from "file-saver";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../util.js";
import { logoutAction } from "../../actions/index.js";

const MainView = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  
  const [state, setStateAction] = useState({
    auditsList: [],
    mainView: true,
    loading: false,
    approveData: {},
    rejectData: {},
    renewalData: {},
    historyView: false,
    selectedHistory: [],
    approveModal: false,
    rejectModal: false,
    renewalModal: false,
    rejectionReason: "",
    expiryDateTime: "",
    emailId: "",
    ccEmail: "",
    calendarView: false,
    selection: [],
    pageSize: 15,
    dateTypeList: [
      { key: 'initiated', value: 'createdAt', text: 'Initiated' },
      { key: 'submitted', value: 'submittedAt', text: 'Submitted' }
    ],
    dateType: "createdAt",
    startDate: "",
    endDate: "",
  })

  const setState = (data) => {
    setStateAction((dt) => {
      return { ...dt, ...data }
    })
  }
  // useEffect(() => {
  //   if (isLoggedIn(authState)) {
  //     navigate('/home');
  //   }
  //   else {
  //     navigate('/', { replace: true });
  //   }
  // },[])
  useEffect(() => {
    getAudits()
  }, [])

  useEffect(() => {
    if (!isLoggedIn(authState)) {
      logoutAction(dispatch)
      navigate(`/login`, { replace: true });
    }
  }, [authState.isLoggedIn])


  const getAudits = () => {
    setState({ loading: true })
    fetchAuditsaction(dispatch, {
      onSuccess: (data) => {
        console.log(data, "Audits List")
        var modifiedData = data.map((item) => ({
          ...item,
          id: item._id,
        })
        )
        console.log(modifiedData, "Modified Audits List")
        setState({
          auditsList: modifiedData,
          loading: false
        })
      },
      onFailure: (data) => {
        setState({ loading: false })
        console.log(data, "Audits List Failure")
      }
    })
  }

  const back = () => {
    setState({
      mainView: true,
      calendarView: false
    });
  };
  const viewCalendar = () => {
    setState({
      calendarView: true
    });
  }

  const closeModal = () => {
    setState({
      approveModal: false,
      rejectModal: false,
      renewalModal: false,
      historyView: false
    })
  }

  const handleTableViewAuditClick = (store) => {
    // console.log(store, "store");
    if (store.status == 'Completed' || store.status == 'Audited') {
      setState({
        // mainView: false,
        id: store._id,
        auditsType: store.auditsType,
        storeName: store.clientName,
      });
    }
    else {
      alert('Audit Not Done for this Case')
    }

  };
  const approveAudit = (data) => {
    console.log("Approve Data", data)
    setState({
      approveData: data,
      approveModal: true
    })
  }
  const rejectAudit = (data) => {
    console.log("Reject Data", data)
    setState({
      rejectData: data,
      emailId: data['auditFormSentTo'],
      ccEmail: "",
      rejectionReason: "",
      expiryDateTime: "",
      rejectModal: true
    })
  }
  const renewalAudit = (data) => {
    console.log("Reject Data", data)
    setState({
      renewalData: data,
      emailId: data['auditFormSentTo'],
      ccEmail: "",
      expiryDateTime: "",
      renewalModal: true
    })
  }
  const openAudit = (data) => {
    console.log(data)
    navigate(`/auditform/${data._id}`);
  }
  const viewHistory = (data) => {
    let selectedHistory = data['auditStatusHistory'] ?? []
    console.log(selectedHistory, data)
    setState({ selectedHistory: selectedHistory, historyView: true })
  }
  const saveApproveAudit = () => {
    const { approveData } = state
    console.log('approveData', approveData)
    // return
    setState({ loading: true })
    approveAuditAction(
      dispatch,
      approveData._id,
      {
        onSuccess: (data) => {
          console.log(data)
          setState({ loading: false, approveModal: false })
          getAudits()
          alert(data.message)
        },
        onFailure: (data) => {
          setState({ loading: false, approveModal: false })
          alert(data)
        }
      });
  }

  const saveRejectAudit = () => {
    const { rejectData, rejectionReason, ccEmail, emailId, expiryDateTime } = state
    console.log('RejectData', rejectData)

    let rejectDataRes = {
      id: rejectData._id,
      rejectionReason,
      emailId,
      mailCC: ccEmail,
      expiryDateTime: expiryDateTime ? moment(expiryDateTime).format("DD/MM/YYYY HH:mm:ss") : '',
    }
    console.log('rejectDataRes', rejectDataRes)
    // return
    setState({ loading: true })
    rejectAuditAction(
      dispatch,
      rejectDataRes,
      {
        onSuccess: (data) => {
          console.log(data)
          setState({ loading: false, rejectModal: false })
          getAudits()
          alert(data.message)
        },
        onFailure: (data) => {
          setState({ loading: false, rejectModal: false })
          alert(data)
        }
      });
  }

  const saveRenewalAudit = () => {
    const { renewalData, ccEmail, emailId, expiryDateTime } = state
    console.log('renewalData', renewalData)

    let renewalDataRes = {
      id: renewalData._id,
      emailId,
      mailCC: ccEmail,
      expiryDateTime: expiryDateTime ? moment(expiryDateTime).format("DD/MM/YYYY HH:mm:ss") : '',
    }
    console.log('renewalDataRes', renewalDataRes)
    // return
    setState({ loading: true })
    renewalAuditAction(
      dispatch,
      renewalDataRes,
      {
        onSuccess: (data) => {
          console.log(data)
          setState({ loading: false, renewalModal: false })
          getAudits()
          alert(data.message)
        },
        onFailure: (data) => {
          setState({ loading: false, renewalModal: false })
          alert(data)
        }
      });
  }

  function getRequestData() {
    let requestData = {
      dateType: state.dateType ?? "",
      filterType: 'all',
      startDate: state.startDate ?? "",
      endDate: state.endDate ?? ""
    }
    if (requestData.startDate != "" || requestData.endDate != "") {
      requestData['filterType'] = 'range'
    }
    return requestData
  }

  const downloadAuditList = async () => {
    try {
      setState({ loading: true })
      console.log("File available")
      let requestData = getRequestData()
      let apiResponse = await downloadAuditsList(requestData)
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, 'AuditList.xlsx')
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }

  const downloadCondolidatedAuditForm = async () => {
    try {
      setState({ loading: true })
      console.log("File available")
      let requestData = getRequestData()
      let apiResponse = await downloadConsolidatedAuditReport(requestData)
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, 'ConsolidatedAuditsForms.xlsx')
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }

  const downloadAuditForm = async (rowData = {}) => {
    console.log("Download PDF", rowData)
    let auditId = rowData?.['_id'] ?? ""
    try {
      setState({ loading: true })
      let apiResponse = await downloadAuditFormPDF({ auditId: auditId })
      console.log('apiResponse', apiResponse)
      let isJsonType = apiResponse?.data?.type == "application/json"
      let jsonResponse = null
      if (isJsonType) {
        jsonResponse = await new Response(apiResponse?.data).json();
        alert(`${jsonResponse?.message ?? "message"}`)
        return
      }
      if (apiResponse?.data) {
        FileSaver.saveAs(apiResponse?.data, `${auditId}_AuditForms.pdf`)
      }
    }
    catch (e) {
      console.log("Error saving", e)
    }
    finally {
      setState({ loading: false })
    }
  }


  const expiryDateTimeChange = (newValue) => {
    console.log(newValue)
    if (newValue) {
      if (newValue._isValid == true) {
        console.log(newValue._d, "Date")
        setState({ expiryDateTime: newValue._d })
      }
    }
  }

  const emailChange = (e) => {
    setState({
      emailId: e.target.value,
    });
  };
  const ccEmailChange = (e) => {
    setState({
      ccEmail: e.target.value,
    });
  };

  const toggleSelection = (key, shift, row) => {
    let selection = [];
    selection = row
    console.log(selection)
    setState({ selection });
  };

  const changeDates = (e, { value }) => setState({ [`${e?.target?.name ?? 'name'}`]: value });

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexFlow: "column",
        height: "86vh",
        paddingTop: '10px'
        // overflowY: "scroll",
        // overflowX: "hidden",
      }}
    >
      <Modal open={state.loading}>
        <Loader>Loading</Loader>
      </Modal>
      {state.mainView && !state.calendarView && (
        <div style={{ height: '81hv', flex: 1, marginBottom: '5hv' }} >
          <div style={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "row" }}  >
            <h1
              style={{
                paddingLeft: 30,
                width: '30vw',
                color: "teal",
                display: "inline-block",
                fontSize: 25,
                fontFamily: "sans-serif",
                fontWeight: "bold"
              }}
            >
              Vendor Audit Management
            </h1>

            <div style={{ width: '40vw' }}>
              {state.dateTypeList.map((item) => {
                return (
                  <Radio
                    key={item.key}
                    label={item.text}
                    value={item.value}
                    onChange={(e, d) => { setState({ 'dateType': d.value }) }}
                    style={{ marginLeft: '10px' }}
                    checked={state.dateType == item.value}
                  />
                )
              })}
              <Input
                type="date"
                size="mini"
                name='startDate'
                label="Start Date"
                style={{ marginLeft: '10px' }}
                onChange={changeDates}
              />
              <Input
                type="date"
                size="mini"
                name='endDate'
                label='End Date'
                style={{ marginLeft: '10px' }}
                onChange={changeDates}
              />
            </div>
            <div style={{ width: '30vw', display: "flex", paddingLeft: '40px', flexDirection: "row" }}>
              <Button
                color="facebook"
                style={{ float: "right", height: '40px', width: '100px' }}
                icon
                labelPosition="right"
                onClick={downloadAuditList}
              >
                <Icon name="download" />
                Audits
              </Button>

              <Button
                color="linkedin"
                style={{ float: "right", height: '40px', width: '150px' }}
                icon
                labelPosition="right"
                onClick={downloadCondolidatedAuditForm}
              >
                <Icon name="download" />
                Consolidated
              </Button>

              <Button
                color="green"
                style={{ float: "right", height: '40px', width: '120px' }}
                icon
                labelPosition="right"
                onClick={viewCalendar}
              >
                <Icon name="calendar alternate" />
                Calender
              </Button>

            </div>
          </div>



          {/* <Divider /> */}


          <div style={{ marginTop: 10, marginLeft: 10, marginRight: 10, marginBottom: '10vh', width: "97vw" }}>
            <TableView
              columns={[
                {
                  title: "Vendor Name",
                  field: "vendorName",
                },
                {
                  title: "Audit Form Sent To",
                  field: "auditFormSentTo",
                },
                {
                  title: "Initated Date",
                  field: "createdAt",
                  filtering: false,
                  render: (rowData) => (<span>{rowData['createdAt']?.split('T')[0] ?? "-"}</span>)
                },
                {
                  title: "Audited Date",
                  field: "submittedAt",
                  filtering: false,
                  render: (rowData) => (<span>{rowData['submittedAt']?.split('T')[0] ?? "-"}</span>)
                },
                {
                  title: "Audit Status",
                  field: "auditStatus",
                },
              ]}
              selection={false}
              // loading={loadingTable}
              data={[...state.auditsList]}
              onRowClick={() => { }}
              actions={[
                rowData => ({
                  icon: 'visibility',
                  iconProps: { color: "primary" },
                  tooltip: 'View Audit',
                  onClick: (event, rowData) => { openAudit(rowData) }
                }),
                rowData => ({
                  icon: 'history',
                  iconProps: { color: "primary" },
                  tooltip: 'View History',
                  onClick: (event, rowData) => { viewHistory(rowData) }
                }),
                rowData => ({
                  icon: 'download',
                  iconProps: { color: "primary" },
                  tooltip: 'Download Audit',
                  onClick: (event, rowData) => { downloadAuditForm(rowData) }
                }),
                rowData => ({
                  icon: 'task_alt', //task_alt  
                  tooltip: 'Approve Audit',
                  iconProps: { color: rowData?.auditStatus === "Audit Form Submitted" ? "success" : "disabled" },
                  onClick: (event, rowData) => { approveAudit(rowData) },
                  disabled: rowData?.auditStatus !== "Audit Form Submitted"
                }),
                rowData => ({
                  icon: 'cancel',
                  tooltip: 'Reject Audit',
                  iconProps: { color: rowData?.auditStatus === "Audit Form Submitted" ? "error" : "disabled" },
                  onClick: (event, rowData) => { rejectAudit(rowData) },
                  disabled: rowData?.auditStatus !== "Audit Form Submitted"
                }),
                rowData => ({
                  icon: 'published_with_changes', //task_alt  
                  tooltip: 'Renew Audit Link',
                  iconProps: { color: rowData?.auditStatus === "Link Expired" ? "default" : "disabled" },
                  onClick: (event, rowData) => { renewalAudit(rowData) },
                  disabled: rowData?.auditStatus !== "Link Expired"
                }),
              ]}
              noofRows={5}
            />
          </div>
        </div>
      )}
      <Modal
        open={state.approveModal}
        onClose={closeModal}
        size="tiny"
      >
        <Modal.Header>
          Approve Audit
        </Modal.Header>
        <Modal.Description>
          <div style={{ marginLeft: 30, marginTop: 20 }}>
            <p>   </p> {`Are you sure want to approve this audit`}
            <p>   </p> Firm Name : {state.approveData?.vendorName ?? ""} ,<br />
            <p>   </p> Email : {state.approveData?.auditFormSentTo ?? ""} ,<br />
          </div>
        </Modal.Description>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="black" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => saveApproveAudit()}
          >
            <Icon name="check circle outline" />  Approve
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        open={state.rejectModal || state.renewalModal}
        onClose={closeModal}
        size="small"
      >
        <Modal.Header>
          {state.rejectModal ? "Reject Audit" : "Renew Audit"}
        </Modal.Header>
        <Modal.Description>
          <div style={{ marginLeft: 20, marginTop: 10 }}>
            {state.rejectModal ?
              <p> Are you sure want to reject this audit of <strong> {state.rejectData?.vendorName ?? ""} </strong> ?</p>
              :
              <p> Are you sure want to renew this audit <strong> {state.rejectData?.vendorName ?? ""} </strong> ?</p>
            }
          </div>
          <Form>
            <Grid columns={2} padded>
              <Grid.Row>
                <Grid.Column width={8} style={{ marginTop: 10 }}>
                  <Form.Input
                    type="text"
                    label="Email"
                    value={state.emailId}
                    onChange={(e) => emailChange(e)}
                    placeholder="enter email"
                  />
                </Grid.Column>
                <Grid.Column width={8} style={{ marginTop: 10 }}>
                  <Form.Input
                    type="text"
                    label="CC Email"
                    value={state.ccEmail}
                    onChange={(e) => ccEmailChange(e)}
                    placeholder="enter cc email"
                  />
                </Grid.Column>
                {state.rejectModal &&
                  <Grid.Column width={8} style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Reject Reason"
                      value={state.rejectionReason}
                      onChange={(e, v) => { setState({ rejectionReason: v.value }) }}
                      placeholder="Enter Reject Reason"
                    />
                  </Grid.Column>
                }

                <Grid.Column width={8} style={{ marginTop: 20 }}>
                  <p style={{
                    fontSize: 13,
                    fontWeight: 'bold',
                    fontFamily: "sans-serif",
                    color: "#000",
                    margin: 0,
                    marginBottom: 5,
                    padding: 0
                  }}>
                    Expiry Date Time
                  </p>
                  <Stack spacing={4} sx={{ width: '100%' }} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        disablePast
                        value={state.expiryDateTime}
                        onChange={expiryDateTimeChange}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Form>
        </Modal.Description>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="black" onClick={closeModal}>
            Cancel
          </Button>
          {state.rejectModal ?
            <Button
              color="red"
              disabled={state.rejectionReason.trim() == "" || state.emailId.trim() == "" || state.expiryDateTime == ""}
              onClick={() => saveRejectAudit()}
            >
              <Icon name="remove" />  Reject
            </Button>
            :
            <Button
              color="green"
              disabled={state.emailId.trim() == "" || state.expiryDateTime == ""}
              onClick={() => saveRenewalAudit()}
            >
              <Icon name="remove" />  Renew Audit
            </Button>
          }
        </Modal.Actions>
      </Modal>

      <Modal
        open={state.historyView}
        onClose={closeModal}
        size="small"
      >
        <Modal.Header>
          Audit History
        </Modal.Header>
        <Modal.Content scrolling >
          <List size="medium" divided>
            {state.selectedHistory.map((item, index) => {
              return (
                <List.Item key={index}>
                  <List.Content>
                    {item?.['status'] ?? ""} - {item?.['createdAt'] ? moment(item.createdAt).format("DD-MMM-YYYY hh:mm A") : "--"}
                    {(item?.auditFormSentTo || item?.auditFormMailCC) &&
                      <><br />To : {item?.auditFormSentTo ?? "--"}, Cc : {item?.auditFormMailCC ?? "--"}</>
                    }
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 20 }}>
          <Button color="black" onClick={closeModal}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>

      {state.calendarView && (
        <CalendarView
          onClose={back}
          auditsType={state.auditsType}
          id={state.id}
          storeName={state.storeName}
        />
      )}
    </div>
  );
}


export default MainView;

import axios from "axios";
import * as types from "./types";

const isUnAuthorized = function (res = {}, dispatch = () => { }) {
    if (res?.['response']?.['status'] == 401) {
        dispatch({
            type: types.LOGOUT_SUCCESS_ACTION
        });

    }
}

export const fetchCalendarListAction = (dispatch, { onSuccess, onFailure }) => {
    axios.get(types.API_URL + `vam/v1/calender/get`)
        .then(function (response) {
            dispatch({
                type: types.CALENDAR_LIST_SUCCESS_ACTION,
                payload: response.data.data
            });
            onSuccess(response.data.data)
        })
        .catch(function (error) {
            console.log('Error on getting data', error)
            dispatch({
                type: types.CALENDAR_LIST_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure()
        });
};

export const getCalendarAction = (dispatch, calendarId, { onSuccess, onFailure }) => {
    axios.get(types.API_URL + `vam/v1/calender/getDetails?id=${calendarId}`)
        .then(function (response) {
            if (response.data.success == true) {

                dispatch({
                    type: types.GET_CALENDAR_SUCCESS_ACTION,
                    payload: response.data.data
                });
                onSuccess(response.data)
            }
            else {
                onFailure(response.data.message)
            }
        })
        .catch(function (error) {
            dispatch({
                type: types.GET_CALENDAR_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure("Something went wrong")
        });
};

export const viewCalendarAction = (dispatch, { onSuccess, onFailure }) => {
    axios.get(types.API_URL + `vam/v1/calender/view`)
        .then(function (response) {
            dispatch({
                type: types.CALENDAR_VIEW_SUCCESS_ACTION,
                payload: response.data.data
            });
            onSuccess(response.data.data)
        })
        .catch(function (error) {
            dispatch({
                type: types.CALENDAR_VIEW_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure()
        });
};

export const addCalendarAction = (dispatch, addVendorData, { onSuccess, onFailure }) => {
    console.log("Add Vendor Data", addVendorData)
    axios.post(types.API_URL + `vam/v1/calender/add`, addVendorData)
        .then(function (response) {
            if (response.data.success == true) {
                dispatch({
                    type: types.ADD_CALENDAR_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess(response.data);
            }
            else {
                onFailure(response.data.message)
            }
        })
        .catch(function (error) {
            isUnAuthorized(error,dispatch)
            onFailure("Something went wrong")
        });
};

export const editCalendarAction = (dispatch, editVendorData, { onSuccess, onFailure }) => {
    console.log("Edit Vendor Data", editVendorData)
    axios.put(types.API_URL + `vam/v1/calender/edit`, editVendorData)
        .then(function (response) {
            if (response.data.success == true) {
                dispatch({
                    type: types.EDIT_CALENDAR_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess(response.data);
            }
            else {
                onFailure(response.data.message)
            }
        })
        .catch(function (error) {
            isUnAuthorized(error,dispatch)
            onFailure("Something went wrong")
        });
};

export const deleteCalendarAction = (dispatch, calendar_id, { onSuccess, onFailure }) => {
    axios.delete(types.API_URL + `vam/v1/calender/delete?id=${calendar_id}`,)
        .then(function (response) {
            if (response.data.success == true) {
                dispatch({
                    type: types.DELETE_CALENDAR_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess(response.data);
            }
            else {
                onFailure(response.data.message)
            }
        })
        .catch(function (error) {
            dispatch({
                type: types.DELETE_CALENDAR_FAILURE_ACTION,
                payload: error
            });
            isUnAuthorized(error,dispatch)
            onFailure();
        });
};

export const approveCalendarAction = (dispatch, approveCalendarData, { onSuccess, onFailure }) => {
    console.log("Approve Calendar Data", approveCalendarData)
    axios.put(types.API_URL + `vam/v1/calender/approveReject`, approveCalendarData)
        .then(function (response) {
            if (response.data.success == true) {
                dispatch({
                    type: types.APPROVE_AUDIT_SUCCESS_ACTION,
                    payload: response.data
                });
                onSuccess(response.data);
            }
            else {
                onFailure(response.data.message)
            }
        })
        .catch(function (error) {
            isUnAuthorized(error,dispatch)
            onFailure("Something went wrong")
        });
};

export const downloadCalendarListAction = async (data = {}) => {
    let downloadData = {}
    if (data && typeof data == 'object') downloadData = { ...data }
    try {
        const apiResponse = await axios.post(
            `${types.API_URL}vam/v1/report/calender/all`,
            downloadData,
            { responseType: "blob" }
        )
        return apiResponse;
    } catch (err) {
        return err.response;
    }
};

export const downloadCalendarFormPDFAction = async () => {
    try {
        const apiResponse = await axios.get(
            `${types.API_URL}vam/v1/report/calender/pdf`,
            { responseType: "blob", }
        )
        return apiResponse;
    } catch (err) {
        return err.response;
    }
};
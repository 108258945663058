import ErrorBoundary from "./components/ErrorBoundary.jsx";
import Login from "./components/Login.jsx";
import Home from "./components/Home.jsx";
import AuditConfirmation from './components/AuditConfirmation.jsx';
import AuditCreation from './components/AuditCreation.jsx';
import VendorCreation from './components/VendorCreation.jsx';
import ControlPanel from "./components/ControlPanel.jsx"

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Store from "./store";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';

const ThemedRouter = (props) => {
  const currentTheme = useSelector(state => state.token)
  const darkTheme = createTheme({
    palette: {
      mode: currentTheme?.theme??'light',
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

const App = () => {
  let persistor = persistStore(Store);
  return (
    <div style={{ height: "100vh" }}>
      <ErrorBoundary>
        <Provider store={Store}>
          <PersistGate persistor={persistor}>
            <ThemedRouter>
              <Router>
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route exact path="/home" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/controlpanel" element={<ControlPanel />} />
                  <Route path="/registrationform/:id" element={<VendorCreation />} />
                  <Route path="/auditform/:id" element={<AuditCreation />} />
                  <Route path="/calenderConfirmation/:id" element={<AuditConfirmation />} />
                </Routes>
              </Router>
            </ThemedRouter>
          </PersistGate>
        </Provider>
      </ErrorBoundary>,
    </div>
  )
}

export default App
